/* Header2.css */

/* Header2.css */
.item-features {
  display: -webkit-box; /* Use a box layout for controlling text lines */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */
  white-space: normal; /* Allow text to wrap */

  font-size: 16px; /* Adjust as per your design */
  line-height: 1.5; /* Set line height */
  max-height: 3em; /* 1.5 * 2 lines = 3em max height */
}



/* Header2.css */
.item-name {
  display: -webkit-box; /* Use a box layout for controlling text lines */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */
  white-space: normal; /* Allow text to wrap */

  font-size: 16px; /* Adjust as per your design */
  line-height: 1.5; /* Set line height */
  max-height: 3em; /* 1.5 * 2 lines = 3em max height */
}




.sidebarcart.open {
    transform: translateX(0);
}

.close-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

/* Sidebar cart header styles */
.sidebarcart-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #333;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.header2 {
  position: sticky;
  top: 0;
  z-index: 1008;
  /* background-color: black  ; */
  background-color: #001a35  ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header2 .company-name {
  font-size: 26px; /* Reduced font size */
  font-weight: 700;
  color: #333;
}

.header2 .search-box {
  position: relative;
  flex-grow: 1;
  margin: 0 15px;
}

.header2 .search-box input {
  width: 100%;
  padding: 8px 40px 8px 15px;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  font-size: 14px; /* Reduced font size */
}

.header2 .search-box button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.header2 .iconss {
  display: flex;
  align-items: center;
  gap:22px;
}

.header2 .iconss svg {
  color: white; /* Dark iconss for contrast */
  font-size: 20px; /* Reduced icon size */
  cursor: pointer;
}
.header2 .iconss .cart-count {
  position: absolute;
  top: -10px;
  right: 25px;
  background-color: red;
  color: white;
  padding: 2px; /* Adjust padding as needed */
  width: 20px; /* Set a fixed width */
  height: 20px; /* Set a fixed height */
  border-radius: 50%; /* Make it round */
  font-size: 12px;
  display: flex; /* Use flexbox to center the text */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  text-align: center; /* Center text */
}


/* .dots{
  margin-left: 18px;
} */
.dots {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  margin-left: 22px;
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.dropdown-menu {
  position: absolute;
  top: 85px; /* Adjust based on icon size and placement */
  right:1px; /* Aligns the dropdown to the right of the icon */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  z-index: 1000;
  width: 180px;
  border-radius: 8px;
}

.iicon{
  color: black;
}
.dropdown-menu a{
  text-decoration: none;
  color: black;
}
.dropdown-menu div {
  padding: 10px 20px;
  cursor: pointer;
}
.dropdown-icon {
  margin-right: 12px;
  font-size: 15px;

}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}
/* Responsive Design */
@media (max-width: 768px) {
  .header2 {
      flex-direction: column;
      gap: 13px;
      text-align: center;
  }

  .dropdown-menu {
    top: 180px; /* Adjust based on icon size and placement */
   
  }

  .header2 .search-box {
      margin: 0;
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .header2 .iconss {
      justify-content: center;
  }

  .footer {
      grid-template-columns: 1fr;
  }

  .dots {
   display: none;
  }

  .header2 .iconss .cart-count {
    position: absolute;
    top: -10px;
    right: -13px;
    background-color: red;
    color: white;
    padding: 1px; /* Adjust padding as needed */
    width: 20px; /* Set a fixed width */
    height: 20px; /* Set a fixed height */
    border-radius: 50%; /* Make it round */
    font-size: 11px;
    display: flex; /* Use flexbox to center the text */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center; /* Center text */
  }
  
}


.sidebarcart {
  position: fixed;
  top: 0;
  right: 0;
  width: 330px;
  height: 100vh; /* Full viewport height */
  background-color: #ffffff;
  color: #333;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.6s ease;
  padding-top: 10px;
  z-index: 1004;
  overflow: hidden; /* Hide overflow to ensure content doesn’t push out */
}

/* Adjusted sidebar content area */
.sidebarcart-body {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: auto; /* Allow scrolling if content overflows */
  height: calc(100vh - 60px); /* Adjust for footer height */
}
.sidebarcart-body p {
    margin-top: 50%; /* Remove default margin */
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically if needed */
}

/* Ensure that items and the footer are correctly positioned */
.sidebarcart-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1; /* Allow ul to take available space */
  margin-bottom: 55px
}

/* Footer at the bottom of the sidebar */
.sidebarcart-footer {
  position: absolute; /* Position at the bottom */
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px; /* Padding for footer content */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensure footer is on top of other content */
  font-size: 16px;

}


.sidebarcart-body li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;  
    padding-bottom: 10px;
}

.sidebarcart-body img {
    width: 80px; /* Adjust size */
    height: 80px; /* Adjust size */
    object-fit: cover;
    margin-right: 10px;
    /* border: 1px solid black; */
}

.item-details {
    flex: 1;
}

.item-details h3 {
    margin: 0;
    font-size: 14px;
}

.item-details p {
    margin: 0;
    color: #555;
    font-size: 14px;

}

.item-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.item-price p {
    margin: 0;
    font-size: 14px;
}

.quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.quantity-controls button {
    background: #f0f0f0;
    border: 1px solid #ddd;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
}

.quantity-controls span {
    margin: 0 10px;
    font-size: 18px;
}

.remove-btn {
    background: #ff4d4f;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
}

/* Sidebar cart footer styles */
/* .sidebarcart-footer {
    position: absolute;
    
    right: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
} */


/* Header2.css */
.cart-icon-container {
    position: relative;
  }
  
  /* .cart-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
    font-weight: bold;
  }
   */


  /* Header2.css */

/* Wishlist Sidebar styles */
.wishlist-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 330px;
    height: 100vh;
    background-color: #ffffff;
    color: #333;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.6s ease;
    padding-top: 10px;
    z-index: 1004;
    overflow: auto;
  }
  
  .wishlist-sidebar.open {
    transform: translateX(0);
  }
  
  .wishlist-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #333;
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .wishlist-body {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  
  .wishlist-body p {
    margin-top: 50%; /* Remove default margin */
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically if needed */
  }
  
  .wishlist-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .wishlist-body li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .wishlist-body img {
    width: 80px; /* Adjust size */
    height: 80px; /* Adjust size */
    object-fit: cover;
    margin-right: 10px;
  }
  
  .item-details {
    flex: 1;
  }
  
  .item-details h3 {
    margin: 0;
    font-size: 14px;
  }
  
  .item-details p {
    margin: 5px 0;
    font-size: 12px;
    color: #666;
  }
  
  .remove-btn {
    background: #f8f8f8;
    border: none;
    color: #ff0000;
    cursor: pointer;
    font-size: 12px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .wishlist-footer {
    text-align: center;
    padding: 10px;
    border-top: 2px solid #333;
  }
  
/* Container for the search box */
/* Container for the search box */
.search-box {
  position: fixed; /* Ensure dropdown is positioned relative to this container */
  display: flex; /* Align input and button horizontally */
  align-items: center; /* Center vertically */
  max-width: 700px; /* Maximum width for the search box */
  margin: 0 auto; /* Center the search box in its container */
  border-radius: 5px; /* Rounded corners for the rectangular shape */
  overflow: hidden; /* Hide overflow for rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  background-color: #fff; /* Background color of the search box */
}

/* Styling for the input field */
.search-box input[type="text"] {
  border: none; /* Remove default border */
  padding: 10px; /* Add padding for spacing */
  font-size: 1rem; /* Font size */
  flex: 1; /* Allow input to take up available space */
  outline: none; /* Remove outline on focus */
}

/* Container for the search icon */
.search-box .search-icon-container {
  display: flex; /* Align items horizontally */
  align-items: center; /* Center icon vertically */
  justify-content: center; /* Center icon horizontally */
  background-color: #ffc68e; /* Light orange background for the box */
  padding: 10px; /* Padding inside the box */
  cursor: pointer; /* Pointer cursor on hover */
  margin-left: 8px; /* Space between the input and the icon box */
}

/* Styling for the search icon */
.search-box .search-icon {
  font-size: 1.2rem; /* Adjust size as needed */
  color: #333; /* Icon color */
}


/* Suggestions dropdown container */
.suggestions-dropdown {
  position: absolute; /* Position dropdown relative to search box */
  top: 100%; /* Position it directly below the search box */
  left: 0;
  width: 100%;
  border: 1px solid #ddd; /* Border for the dropdown */
  border-radius: 4px; /* Rounded corners for dropdown */
  background-color: white; /* Background color for dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dropdown */
  max-height: 200px; /* Max height for scrolling */
  overflow-y: auto; /* Enable vertical scroll if needed */
  z-index: 1000; /* Ensure dropdown appears above other elements */
}

/* Each suggestion item */
.suggestions-dropdown li {
  padding: 10px 15px; /* Padding for items */
  cursor: pointer; /* Pointer cursor on hover */
  list-style: none; /* Remove default list style */
  font-size: 0.875rem; /* Font size for suggestions */
  transition: background-color 0.3s; /* Smooth background color transition */
}

/* Hover state for suggestion items */
.suggestions-dropdown li:hover {
  background-color: #f1f1f1; /* Light background color on hover */
}

/* Error message styling */
.no-suggestions {
  padding: 10px 15px; /* Padding for error message */
  color: red; /* Red text color for errors */
  font-size: 0.875rem; /* Slightly smaller font size */
  text-align: center; /* Center text alignment */
  list-style: none; /* Remove default list style */
  background-color: #fff; /* Background color for error message */
}

/* Error message styling */
.error-message {
  color: rgb(53, 49, 49); /* Red text color for errors */
  font-size: 0.875rem; /* Slightly smaller font size */
  margin-top: 10px; /* Space above the error message */
}

.iconss {
  display: flex;
  align-items: center;
  margin-left: 30px;
  gap: 40px;
 
}

.search-icon {
  margin-right: 12px;
}
/* Button hover effect */
.search-box button:hover {
  background-color: #0056b3; /* Darker background on hover */
}

/* Optional: Add responsive design for smaller screens */
@media (max-width: 768px) {
  .search-box {
      width: 100%; /* Full width on smaller screens */
      max-width: none; /* Remove max-width constraint */
  }
  
}

/* Styling for the cart total price */
.cart-total p {
  margin: 0;
  font-size: 16px; /* Font size for total price */
  font-weight: bold; /* Bold text */
  
}



.sidebarcart-footer p {
  margin: 0; /* Remove default margin */
}

.sidebarcart-footer a {
  text-decoration: none; /* Remove underline */
  color: black; /* Text color */
}

/* Style the scrollbar track */
.sidebarcart-body::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Style the scrollbar handle */
.sidebarcart-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for the scrollbar handle */
  border: 3px solid #fff; /* Adds some space between handle and track */
}

/* Style the scrollbar handle on hover */
.sidebarcart-body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovering */
}

/* Optional: Style the scrollbar track (area not covered by handle) */
.sidebarcart-body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}









