/* CareersTable.css */

/* Container styles */
.careers-table-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-left: 290px;
  margin-top: 90px;
}

/* Heading styles */
.careers-table-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

/* Table styles */
.careers-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Allows columns to adjust based on content */
}

.careers-table thead {
  background-color: #007bff;
  color: white;
}

.careers-table th, .careers-table td {
  border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.careers-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.careers-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.careers-table tr:hover {
  background-color: #e0e0e0;
}

.resume-btn {
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

/* .resume-btn:hover {
  background-color: #0056b3;
} */

/* Responsive Table Design */
@media (max-width: 768px) {
  .careers-table-container {
    margin-left: 0; /* Remove left margin */
    padding: 10px; /* Add some padding */
  }

  .careers-table {
    width: 100%;
    overflow-x: auto;
    display: block; /* Make table scrollable horizontally */
    border-collapse: separate;
    border-spacing: 0; /* Avoid collapsing borders */
  }

  .careers-table th, .careers-table td {
    padding: 10px 8px;
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .careers-table th, .careers-table td {
    white-space: nowrap; /* Prevent text wrapping */
  }

  .careers-table th {
    font-size: 14px; /* Reduce header font size */
    text-transform: none; /* Remove text transformation */
  }

  .careers-table tr {
    display: table-row; /* Retain table row structure */
  }

  .careers-table tr:hover {
    background-color: transparent; /* Remove hover effect on mobile */
  }
  
  .resume-btn {
    font-size: 12px; /* Reduce button size */
    padding: 6px 10px;
  }
}
/* Modal styles */
.modall-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modall-content {
  background: white;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  width: 70%; /* Adjust width for modal */
  max-width: 600px; /* Adjust max-width for modal */
}

.modall-close {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 4%;
  background-color: red;
}
.download-btn {
  top: 2px;
  right: 25px;
  border: none;
  display: none;
  font-size: 20px;
  cursor: pointer;
  /* color: #007bff; */
  /* border-radius: 50%; */
  width: 4%;
  /* background-color: rgb(40, 94, 245); */
}

.modall-iframe {
  width: 100%; /* Full width within the modal */
  height: 530px; /* Adjust height for modal */
  border: none;
}

/* Responsive table design */
@media (max-width: 768px) {
  .modall-content {
    width: 90%; /* Adjust width for smaller screens */
    max-width: 100%; /* Full width on smaller screens */
  }

  .modall-iframe {
    display: none; /* Hide iframe on small screens */
  }

  .resume-btn {
    display: inline-block; /* Ensure button is visible */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .resume-btn:hover {
    background-color: #0056b3;
  }

  /* Change resume button to a download link */
  .download-btn {
    display: block;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    color: #007bff;
    text-decoration: none;
    border-radius: 5px;
    background-color: transparent;
  }

  .download-btn:hover {
    background-color: #f0f0f0;
  }
}

