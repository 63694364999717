/* Base Styles */
.topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #001a35;
    color: rgb(49, 49, 49);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 17px 20px;
    z-index: 1000;
    transition: all 0.3s ease;
    display: flex;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

}

.topbar-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}

.topbar-logo {
    display: none; /* Hide logo if aligning everything to the right */
}

.menu-toggle {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    display: block;
    margin-right: 20px; /* Space before actions */
}

.topbar-search {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.topbar-search input {
    padding: 5px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 14px; /* Adjust font size for better visibility */
}

.search-icon {
    font-size: 18px;
    color: rgb(53, 50, 50);
    margin-left: 10px; /* Adjust spacing between input and icon */
}

.topbar-actions {
    display: flex;
    align-items: center;
    gap: 15px;
}

.action-btn {
    background: none;
    border: none;
    color: rgb(41, 40, 40);
    font-size: 20px;
    cursor: pointer;
    margin-right: 20px;
    padding-top: 11px;
}

.topbar-menu .menu-item {
    display: flex;
    align-items: center;
    gap: 15px; /* Space between icon and text */
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;
    
}


.topbar-menu {
    position: absolute;
    top: 78px; /* Adjust top position */
    right: 1; /* Align to the right */
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    width: 180px; /* Adjust width */
    z-index: 1000; /* Ensure it is above other elements */
}

.menu-item {
    background: none;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    text-align: left;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border do not affect width */
}

.menu-item:hover {
    background-color: #555;
    color: white;
}

/* Responsive Styles */
@media (max-width: 768px) {

    .notification-box {
        width: 100%;
        right: 0;
      }
    
    .topbar-content {
        flex-direction: row; /* Ensure content is in a single row */
        align-items: center; /* Center items vertically */
        justify-content: right; /* Space between items */
    }

    .menu-toggle {
        display: block;
        margin-right: 1%;
    }

    .action-btn {

        margin-right: 20px;


    }


    .topbar-search {

margin-right: 1px;    }

    .topbar-actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .topbar.menu-open .topbar-actions {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #333;
        border-radius: 5px;
        width: 100%;
        padding: 10px 20px;
        gap: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .topbar.menu-open .topbar-menu {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .action-btn {
        font-size: 18px;
    }
    

    .search-icon {
        font-size: 16px;
    }
}


.profile-section {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
    padding: 0;
    
  }
  
  .profile-imagee {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
    padding: 2px 2px;
  }
  
  .profile-username {
    font-size: 18px;
    font-weight: bold;
    color: rgb(34, 33, 33);
  }
  .userpic{
    width: 40px;
    cursor: pointer;
    border-radius: 50%;
  }
  /* .admin{
    font-size: 10px;
    color: black;
  } */
 /* Enhanced Notification Box */

 
 .no-notify{
  margin-top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.notification-box {
  position: absolute;
  top: 75px;
  right: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.notification-header {
  display: flex;
  justify-content: space-between; /* Distributes space between the two headings */
  align-items: center;
  background-color: #001a35;
  color: white;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.notification-header h4 {
  font-size: 18px;
  margin: 0;
}

.notification-header .mark-all-btn {
  cursor: pointer;
  font-size: 14px;
  color: #4CAF50; /* Mark All button color */
}

 
  
  /* Notification List */
  .notification-box ul {
    list-style-type: none;
    /* padding-right: 10px;
    padding-left: 10px; */
    padding: 10px;
    margin: 0;
    overflow-y: auto;
    flex-grow: 1;
    /* margin-top: 10px; */
  }
  
  .notification-box ul li {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    /* padding: 5px ; */
    /* border-bottom: 1px solid #f1f1f1; */
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  /* Notification Count Badge */
.notification-count {
    position: absolute;
    top: 15px;
    right: 75px;
    background-color: red;
    color: white;
    font-size: 12px;
    /* font-weight: bold; */
    width: 20px;
    height: 20px;
    padding: 3px ;
    border-radius: 50%;
    min-width: 18px;
    text-align: center;
  }
  
  
  .notification-box ul li:last-child {
    border-bottom: none;
  }
  
  /* Notification Content */
  .notification-box ul li .content {
    flex-grow: 1;
    margin-right: 10px;
  }
  
  /* Circle Button (Read/Unread) */
 /* Circle Button (Read/Unread) */
.notification-box ul li .circle-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;  /* Aligns the circle button to the right */
    flex: 0; /* Prevents the wrapper from taking up extra space */
    margin-left: auto; /* Ensures that the circle buttons stay on the far right */
  }
  
  .circle-btn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #007bff;
    background-color: transparent;
    cursor: pointer;
  }
  
  .circle-btn.unread {
    background-color: #007bff;
  }
  
  .circle-btn.read {
    background-color: transparent;
    border: 1px solid #888;
  }
  
  /* New/Read Notification Styles */
  .notification-box ul li.new {
    /* background-color: #e0f7fa;  */
    font-weight: bold;
  }
  
  .notification-box ul li.read {
    background-color: transparent; /* Transparent background for read notifications */
    color: #888; /* Grey color for read notifications */
  }
  
  .notification-box ul li.unread {
    background-color: #fff; /* White background for unread notifications */
  }
  
  .notification-box ul li .timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    display: block;
    margin-left: 20px; /* Optional: aligns timestamp with content */
  }
  
  /* Mark All Button */
  .notification-box .mark-all-btn {
    margin-top: 10px;
    align-self: flex-start; /* Align it to the start */
  }

  /* Notification Item */
.notification-item {
    display: flex; /* Use flex to arrange items in a row for message and circle button */
    flex-direction: column; /* Stack the content vertically */
    padding: 10px 5px;
    border-bottom: 1px solid #f1f1f1;
    font-size: 14px;
    color: #333;
  }
  
  /* Notification Row (Message and Circle Button) */
  .notification-row {
    display: flex; /* Arrange message and circle button in the same row */
    justify-content: space-between; /* Space out the content, push circle to the right */
    align-items: center; /* Vertically center the items */
    width: 100%;
  }
  
  /* Circle Button Wrapper */
  .circle-btn-wrapper {
    display: flex;
    justify-content: flex-end; /* Ensure circle button is aligned to the right */
    flex: 0; /* Prevent wrapper from taking extra space */
  }
  
  /* Circle Button */
  .circle-btn {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #007bff;
    background-color: transparent;
    cursor: pointer;
  }
  
  .circle-btn.unread {
    background-color: #007bff;
  }
  
  .circle-btn.read {
    background-color: transparent;
    border: 1px solid #888;
  }
  
  /* Notification Time */
  .notification-time {
    font-size: 12px;
    color: #888;
    margin-top: 5px; /* Add space above the time */
  }
  
  /* Clear Option */
  .notification-box .clear {
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #888;
  }
  
  .notification-box .clear:hover {
    text-decoration: underline;
  }
  
  /* Marking Read Notifications */
  .notification-item.read {
    background-color: transparent; /* Remove background for read notifications */
  }
  
  .notification-item.new {
    /* background-color: #e0f7fa;  */
    font-weight: bold;
  }
  
  .notification-item.unread {
    background-color: #fff;
  }
  
  /* Improved Topbar and Menu Styles */

