.ad-cards-container {
  display: flex;                     /* Use flexbox to arrange items */
  flex-wrap: wrap;                  /* Allow wrapping of cards to the next line */
  gap: 30px;                        /* Adjust the gap between product cards */
  justify-content: center;       
  /* justify-content: flex-start;        */
  align-items: center;               /* Center the cards vertically if they are in different rows */
  max-width: 100%;                  /* Allow the container to take full width */
  margin: 10px auto;                /* Center the container horizontally with auto margins */
  padding: 10px;                    /* Optional: Add padding if needed */
  box-sizing: border-box;            /* Ensure padding and border are included in the total width and height */
}


.ad-card {
  flex: 0 0 calc(33.33% - 20px);    /* Default to three cards per row */
  height: 380px;                    /* Set a fixed height for the card (adjust as needed) */
  box-sizing: border-box;            /* Include padding and border in element's total width/height */
  background-color: #fff;            /* Background color */
  border-radius: 5px;               /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  padding: 15px;                    /* Inner padding */
  margin-bottom: 20px;              /* Space below each card */
  position: relative;               /* Positioning context for child elements */
  overflow: hidden;                  /* Optional: Prevent overflow of inner content */
}

.ad-cardd {
  width: 100%;
    height: auto;                    /* Set a fixed height for the card (adjust as needed) */
  box-sizing: border-box;            /* Include padding and border in element's total width/height */
  background-color: #fff;            /* Background color */
  border-radius: 5px;               /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  padding: 15px;                    /* Inner padding */
  margin-bottom: 20px;              /* Space below each card */
  position: relative;               /* Positioning context for child elements */
  overflow: hidden;                  /* Optional: Prevent overflow of inner content */
}
.ad-cardd4 {
  width: 60%;
    height: 380px;                    /* Set a fixed height for the card (adjust as needed) */
  box-sizing: border-box;            /* Include padding and border in element's total width/height */
  background-color: #fff;            /* Background color */
  border-radius: 5px;               /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  padding: 15px;                    /* Inner padding */
  margin-bottom: 20px;              /* Space below each card */
  position: relative;               /* Positioning context for child elements */
  overflow: hidden;                  /* Optional: Prevent overflow of inner content */
}

.ad-card3 {
  flex: 0 0 calc(33.33% - 20px); /* Default to three cards per row */
  height: 160px; /* Set a fixed height for the card (adjust as needed) */
  box-sizing: border-box; /* Include padding and border in element's total width/height */
  background-color: #fff; /* Background color */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
  padding: 15px; /* Inner padding */
  margin-bottom: 20px; /* Space below each card */
  position: relative; /* Positioning context for child elements */
  overflow: hidden; /* Prevent overflow of inner content */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .ad-card3 {
    flex: 0 0 calc(50% - 20px); /* Two cards per row on medium screens */
  }
}

@media (max-width: 768px) {
  .ad-card3 {
    flex: 0 0 calc(100% - 20px); /* One card per row on small screens */
    height: auto; /* Allow height to adjust based on content */
  }
  .ad-cardd4 {
    width: 100%;
      height: auto;                    /* Set a fixed height for the card (adjust as needed) */
    box-sizing: border-box;            /* Include padding and border in element's total width/height */
    background-color: #fff;            /* Background color */
    border-radius: 5px;               /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    padding: 15px;                    /* Inner padding */
    margin-bottom: 20px;              /* Space below each card */
    position: relative;               /* Positioning context for child elements */
    overflow: hidden;                  /* Optional: Prevent overflow of inner content */
  }


  

}
/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .ad-card {
    flex: 0 0 calc(50% - 20px);    /* Two cards per row for tablets */
  }
}
@media (max-width: 480px) {
  .ad-image4 {
    width: 100%; /* Make the image take up the full width of the container */
    height: auto; /* Fixed height for uniformity */
    object-fit: contain; /* Crop the image to fit within the dimensions */
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .ad-card {
    flex: 0 0 calc(100% - 20px);   /* One card per row for mobile */
  }
  
}

  
  .ad-card-content {
    margin-bottom: 15px;
  }
  
  .ad-info-card h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .ad-info-card p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .laptops-edit-btn {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .ad-images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .ad-image-card {
    flex: 1 1 calc(50% - 10px); /* 50% width minus gap */
    box-sizing: border-box;
    position: relative;
  }
  
  .ad-image {
    width: 100%; /* Make the image take up the full width of the container */
    height: 120px; /* Fixed height for uniformity */
    object-fit: contain; /* Crop the image to fit within the dimensions */
    border-radius: 5px;
  }
  
  .ad-image3 {
    width: 100%; /* Make the image take up the full width of the container */
    height: 320px; /* Fixed height for uniformity */
    object-fit: cover; /* Crop the image to fit within the dimensions */
    border-radius: 5px;
  }
  
  .ad-image4 {
    width: 100%; /* Make the image take up the full width of the container */
    height: 350px; /* Fixed height for uniformity */
    object-fit: contain; /* Crop the image to fit within the dimensions */
    border-radius: 5px;
  }
  
  .ad-image2 {
    width: 100%; /* Make the image take up the full width of the container */
    height: 230px; /* Fixed height for uniformity */
    object-fit: contain; /* Crop the image to fit within the dimensions */
    border-radius: 5px;
  }
  
  .image-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 5px;
  }
  
  .edit-icon,
  .delete-icon {
    cursor: pointer;
    font-size: 16px;
  }
  
  .edit-icon {
    color: #007bff;
  }
  
  .delete-icon {
    color: #dc3545;
  }
  
 