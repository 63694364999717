.prev-btn {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.next-btn {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.prev-btn:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}
.next-btn:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.custom-slider-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 100%; /* Ensure full width of the swiper slide */
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 2px 4px 6px  rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
     min-height: 400px; /* Ensure all cards have the same minimum height */
    height: auto; /* Adjust height dynamically */
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;
}

.custom-slider-product.blurred {
    filter: blur(3px);
    pointer-events: none; /* Optional: disable interaction */
    z-index: 1; /* Lower the z-index of the blurred product */
}

/* Wrapper for the "See More" button to make sure it is outside the blurred effect */
.see-more-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Ensure the wrapper is above the blurred product */
    display: none; /* Hide by default */
}
/* Responsive styling for smaller screens */
@media (max-width: 1024px) {
    .custom-slider-product {
        width: 100%;
    }
    .product-name, .product-price {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .custom-slider-product {
        width: 100%;
        padding: 10px;
    }

}

@media (max-width: 480px) {
    .custom-slider-product {
        width: 100%;
        padding: 5px;
        justify-content: space-around;
    }

    .custom-slider-image {
        width: 100%;
        height: 130px !important;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 10px;
    }
    
}
/* Style the "See More" button */
.see-more-btn {
    background-color: rgba(0, 0, 0, 0.7);
    color: rgb(255, 255, 255);
    /* background: transparent; */
    border: 1px solid rgba(145, 143, 143, 0.7);
    /* border: none; */
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px;
}

/* When the last product has blur applied, show the button */
.product-slide.last-product .see-more-wrapper {
    display: block;
}

/* Hover effect for the "See More" button */
.see-more-btn:hover {
    background-color: rgba(0, 0, 0, 0.9);
    /* color: white; */
}

/* Hover effect for the product container */
/* .custom-slider-product:hover {
    transform: translateY(-5px);
} */
/* Label for offer on products */
.custom-slider-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #f00;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
}

/* Image styling */
.custom-slider-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Favorite icon style */
.custom-slider-favorite {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff4040;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Filled favorite icon */
.custom-slider-favorite.filled {
    color: #ff4040;
}

/* Product name style */
.custom-slider-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
    text-transform: capitalize;
}


.custom-slider-name {
    display: -webkit-box; /* Required for WebKit-based browsers */
    /* display: box;                      */
    -webkit-box-orient: vertical; /* Required for WebKit-based browsers */
    box-orient: vertical; /* Fallback for other browsers */
    overflow: hidden; /* Hide overflow content */
    -webkit-line-clamp: 2; /* Limit to 2 lines in WebKit-based browsers */
    line-clamp: 2; /* Future proofing for standard implementation */
    text-overflow: ellipsis; /* Show ellipsis when text overflows */
  }
/* Product subtitle style */
.custom-slider-subtitle {
    font-size: 14px;
    color: #777;
    margin-bottom: 10px;
}

/* Price styling */
.custom-slider-price {
    font-size: 18px;
    color: #000;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Out of stock message */
.custom-slider-out-of-stock {
    font-size: 14px;
    color: #e74c3c;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Add to cart button styling */
.custom-slider-add-to-cart {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Hover effect for Add to Cart button */
.custom-slider-add-to-cart:hover {
    background-color: #218838;
}
.product-list-container {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}