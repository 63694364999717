/* Active link styles */
.sidebar ul li a.active {
    background-color: #007bff; /* Set the background color for active link */
    color: white; /* Set the text color for active link */
    font-weight: bold; /* Make the active link bold */
    width: 100%;
}

/* Optional: Active link hover effect */
.sidebar ul li a.active:hover {
    width: 100%;
    background-color: #0056b3; /* Darken background on hover for active link */
}


.sidebar {
    position: sticky; /* Makes sidebar stick while scrolling */
    top: 0; /* Stick to the top */
    left: 0;
    width: 250px;
    height: 100vh; /* Full height */
    background-color: #f8f8f8;
    border-right: 1px solid #ddd;
    padding: 20px;
    overflow-y: auto; /* Enable scrolling if content is long */
}

/* Custom Scrollbar */
.sidebar::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the track color */
    border-radius: 10px; /* Rounded corners for the track */
}

.sidebar::-webkit-scrollbar-thumb {
    background: #888; /* Set the color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    transition: background-color 0.3s; /* Smooth transition */
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
}

.sidebar::-webkit-scrollbar-button {
    background: #ccc; /* Button color on the scrollbar */
}

.sidebar.open {
    transform: translateX(0); /* Show the sidebar */
}

.sidebar-content {
    position: relative; /* Needed for positioning the close button */
}

.close-button {
    position: absolute; /* Position absolutely within the sidebar */
    top: 10px;
    right: 10px; /* Move close button to the right */
    font-size: 24px; /* Size of the close button */
    cursor: pointer; /* Change cursor to pointer on hover */
}

/* Toggle Button Styles */
.toggle-button {
    position: fixed; /* Keeps the toggle button fixed in place */
    top: 280px; /* Distance from the top of the viewport */
    left: 20px; /* Distance from the left of the viewport */
    font-size: 22px; /* Size of the icon */
    cursor: pointer; /* Change cursor to pointer on hover */
    z-index: 1000; /* Ensure it’s above other content */
    display: none; /* Hide by default */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .toggle-button {
        display: block; /* Show the toggle button on small screens */
    }

        /* .sidebar {
        width: 80%;
        border-right: none;
        border-bottom: 1px solid #ddd;
    } */
    .sidebar {
        transform: translateX(-100%); /* Hide sidebar off-screen */
    }

    .sidebar.open {
        transform: translateX(0); /* Show sidebar */
    }
}

@media (max-width: 768px) {
    .sidebar {
        position: fixed; /* Keep sidebar fixed for small screens */
        transform: translateX(-100%); /* Initially hide sidebar */
        transition: transform 0.3s ease-in-out;
        width: 250px;
    }

    .sidebar.open {
        transform: translateX(0); /* Show sidebar when toggled */
    }

    .main-content {
        margin-left: 0; /* No margin for smaller screens */
    }
}

/* Sidebar List Styles */
.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-bottom: 10px;
    
}

.sidebar ul li a {
    display: flex;
    align-items: center; /* Align items vertically */
    text-decoration: none;
    color: #333;
    padding: 10px; /* Add padding for better click area */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth background color transition */
    width: 100%;

}

.sidebar ul li a:hover {
    background-color: #ddd; /* Highlight background on hover */
    width: 100%;
}

.sidebar ul li a svg {
    margin-right: 10px; /* Space between icon and text */
    font-size: 18px; /* Adjust icon size */
}

@media (max-width: 768px) {
    .toggle-btn {
        display: block; /* Show the toggle button on small screens */
    }
    .slidebar {
        transform: translateX(-100%); /* Hide sidebar off-screen */
    }

    .slidebar.open {
        transform: translateX(0); /* Show sidebar */
    }

    .menu-btn {
        display: block; /* Show the toggle button on small screens */
    }
}

@media (min-width: 769px) {
    .toggle-btn {
        display: none; /* Hide the toggle button on larger screens */
    }
    .menu-btn {
        display: none; /* Hide the toggle button on larger screens */
    }
}

/* Position the hamburger icon */
.hamburger-icon {
    position: fixed;
    top: 26px; /* Adjust as needed */
    left: 20px; /* Adjust as needed */
    font-size: 24px; /* Adjust size as needed */
    color: #ffffff; /* Adjust color as needed */
    z-index: 1001; /* Ensure it's above other content */
    cursor: pointer;
  }