.staff-management-container {
    padding: 20px;
    /* background-color: #f9f9f9; */
    margin-left: 279px;
    margin-top: 50px;
  }
  
  .staff-main-content {
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Form */
  .staff-form {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .staff-form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .staff-form-group label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .staff-form-group input,
.staff-form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}
.staff-input.password-hidden {
  -webkit-text-security: disc; /* Mimic password dots */
}
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex: 1;
  padding-right: 40px; /* To make space for the icon */
}

.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #777;
}

.eye-icon:hover {
  color: #000;
}

 /* Focus styles */
.staff-input:focus ,
.staff-input3:focus 
{
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a glowing effect */
    outline: none; /* Remove default outline */
    transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  }

  .staff-input:hover,
  .staff-input3:hover
   {
  border-color: #0056b3; 
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6); 
}

.custom-input:focus {
  border-color: #007bff !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important;
  outline: none !important;
}

.custom-input:hover {
  border-color: #0056b3 !important;
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6) !important;
}
  
  .staff-error-input {
    border-color: red;
  }
  
  .staff-error-text {
    color: red;
    font-size: 12px;
  }
  
  .staff-btn-submit {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 22px;
    align-self: center;
  }
  
  .staff-btn-submit:hover {
    background-color: #45a049;
  }
  
  /* Table */
  .staff-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .staff-table th,
  .staff-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
  }
  
  .staff-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .staff-btn-edit,
  .staff-btn-delete {
    padding: 8px 12px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .staff-btn-edit {
    background-color: #ffc107;
    color: white;
    margin-right: 10px;
  }
  
  .staff-btn-edit:hover {
    background-color: #e0a800;
  }
  
  .staff-btn-delete {
    background-color: #dc3545;
    color: white;
  }
  
  .staff-btn-delete:hover {
    background-color: #c82333;
  }
  

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-btn {
    margin: 0 5px;
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color: #f1f1f1;
  }
  
  .page-btn.active {
    background-color: #3085d6;
    color: white;
  }
  
  .page-btn:hover {
    background-color: #ddd;
  }
  