/* General Styles */
.btn-print {
  background-color: transparent; /* Green background */
  color: #45a049; /* White text */
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}


.btn-cancel {
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.btn-cancel:disabled {
  background-color: #eecdcd;
  cursor: not-allowed;
  color: #dc3545;
}


.row-cancelled {
  background-color: #ffe6e6; /* Light red background */
  color: #585858; /* Dark red text */
}

.row-delivered {
  background-color: #e8ffe6; /* Light red background */
  color: #585858; /* Dark red text */
}


.orders-page {
  margin-left: 250px; /* Adjust according to the width of the sidebar */
  margin-top: 60px;   /* Adjust according to the height of the top bar */
  padding: 20px;
  overflow-x: auto; /* Add horizontal scrolling to the page if needed */
  background-color: #f9f9f9; /* Light background for better contrast */
}

@media (max-width: 1024px) {
  .orders-page {
    margin-left: 0; /* Adjust for smaller screens */
  }
}

.orders-header {
  margin-bottom: 20px;
}

.orders-page-title {
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 24px;
}

/* Search box container to align it with the table */
/* Normal Styles - These styles will apply to larger screens */
.search-box2-container {
  margin-bottom: 20px; /* Space between search and table */
  display: flex;
  justify-content: flex-end; /* Aligns the search box to the right */
  width: 100%; /* Ensures it takes full width */
  position: relative;
}

.clear-button {
  position: absolute;
  right: 10px; /* Position the button on the right side */
  top: 35%; /* Vertically center the button */
  transform: translateY(-50%); /* Adjust vertical centering */
  background-color: transparent;
  border: none;
  color: #888;
  cursor: pointer;
  font-size: 14px;
}

.filters-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end; /* Aligns the filters to the right */
  margin-bottom: 15px;
}

.filter-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 1px;
  font-size: 14px;
  margin-right: 10px;
}

.filter-select:focus,
.filter-select.selected {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.filter-select:hover {
  border-color: #0056b3; 
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6); 
}

.clear-button:hover {
  color: #333;
}

.filter-radio-buttons {
  display: flex;
  flex-direction: row; /* Align radio buttons in a row */
  gap: 20px; /* Adds space between the radio buttons */
  padding: 10px;
  flex-wrap: wrap; /* Allows wrapping if there are too many items */
}

.filter-radio-buttons label {
  display: flex;
  align-items: center; /* Centers the text and radio button vertically */
  font-size: 14px; /* Set font size for labels */
}

.filter-radio-buttons input[type="radio"] {
  margin-right: 3px; /* Adds space between the radio button and the label */
  accent-color: #007bff; /* Change radio button color (optional) */
}

.search-box2 {
  padding: 8px 12px;
  width: 250px; /* Adjust the width as needed */
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 1px;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Adds light shadow */
  transition: all 0.3s ease; /* Smooth transition for focus */
}

.search-box2:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Focus effect */
}

.search-box2:hover {
  border-color: #0056b3; 
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6); 
}

/* Responsive Styles - These styles will only apply for smaller screens */
@media (max-width: 768px) {
  .search-box2-container {
    display: flex;
    flex-direction: column; /* Stack the filters and search box vertically */
    margin-bottom: 15px; /* Adjust margin for better spacing */
    width: 100%; /* Ensure it takes full width */
  }

  /* Row 1: Filters */
  .filters-container {
    display: flex;
    justify-content: space-between; /* Space the filters evenly */
    gap: 10px; /* Add space between filter items */
    margin-bottom: 15px;
    flex-wrap: wrap; /* Ensure wrapping if items overflow */
  }

  /* Row 2: Month and Year */
  .month-year-container {
    display: flex;
    gap: 10px; /* Space between month and year dropdown */
    margin-bottom: 15px;
  }

  /* Row 3: Search Box */
  .search-box2-container {
    display: flex;
    justify-content: flex-start; /* Align search input */
    width: 100%;
  }

  .search-box2 {
    width: 100%; /* Make search box take full width on smaller screens */
    margin-top: 10px; /* Add space between search and other elements */
  }

  .clear-button {
    font-size: 14px; /* Adjust clear button size */
  }

  /* Filters layout in a row */
  .filter-radio-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%; /* Ensure the radio buttons fill the container */
  }

  .filter-radio-buttons label {
    font-size: 14px; /* Adjust font size */
    display: flex;
    align-items: center; /* Align radio button and label */
  }

  .filter-select {
    width: 100%; /* Make dropdowns take full width */
  }
}

@media (max-width: 480px) {
  .search-box2 {
    width: 97% !important; /* Ensure search box is responsive on very small screens */
  }

  .filter-radio-buttons label {
    font-size: 12px; /* Adjust font size of radio button labels */
  }

  .clear-button {
    font-size: 12px; /* Adjust clear button size for small screens */
    margin-top: 93px;
    margin-right: 10px;
  }
} 


.status-new-order {
  color: #007bff; /* Bright Blue for New Order */
}

.status-shipped {
  color: #ff6f00; /* Vibrant Orange for Shipped */
}

.status-Out-of-Delivery {
  color: #ffbf00; /* Warm Yellow-Orange for Out for Delivery */
}

.status-delivered {
  color: #28a745; /* Bright Green for Delivered */
}

.status-cancelled {
  color: #dc3545; /* Bold Red for Cancelled */
}

.status-unknown {
  color: #6c757d; /* Muted Gray for Unknown */
}

/* Styling for the search box */
.search-box2 {
  padding: 8px 12px;
  width: 250px; /* Adjust the width as needed */
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 1px;
  outline: none;
  height: 36px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Adds light shadow */
  transition: all 0.3s ease; /* Smooth transition for focus */
}

/* Focus state for the search box */
.search-box2:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Focus effect */
}

.table-wrapper {
  overflow-x: auto; /* Add horizontal scrolling to the table container */
  margin-top: 20px; /* Space from search box */

}

.table-wrapper::-webkit-scrollbar {
  width: 10px !important; /* Width of the vertical scrollbar */
  height: 10px !important; /* Height of the horizontal scrollbar */
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #e91e63; /* Scrollbar thumb color */
  border-radius: 10px; /* Round the corners of the scrollbar thumb */
  border: 2px solid #f4f4f4; /* Optional: Add padding effect around thumb */
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #c2185b; /* Darker color on hover */
}

.table-wrapper::-webkit-scrollbar-track {
  background-color: #f4f4f4; /* Scrollbar track color */
  border-radius: 10px; /* Match thumb border-radius */
}

/* Custom scrollbar for Firefox */
.table-wrapper {
  scrollbar-width: thin; /* Make scrollbar thin */
  scrollbar-color: #e91e63 #f4f4f4; /* Set thumb and track colors */
}

/* Optional: Table-specific styles for better appearance */
.orders-table {
  width: 100%; /* Make the table fill the wrapper */
  border-collapse: collapse; /* Remove spacing between table cells */
}


.orders-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.orders-table th,
.orders-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.orders-table th {
  background-color: #e0e0e0;
  color: #333;
}

/* .orders-table tbody tr:nth-child(even) {
  background-color: #fafafa;
} */

.orders-table tbody tr:hover {
  background-color: #f0f0f0; /* Highlight row on hover */
}

.orders-table .status {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: bold;
  text-transform: capitalize;
  /* color: #fff; */
}

.orders-table .status.processing {
  background-color: #ff9800;
}

.orders-table .status.shipped {
  background-color: #3f51b5;
}

.orders-table .status.delivered {
  background-color: #4caf50;
}

.btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-view, .btn-delete {
  background-color: transparent;
  display: inline-flex; /* Ensure buttons stay inline */
  align-items: center; /* Align icon with text */
  justify-content: center;
  color: inherit;
  border: none; /* Remove button borders if any */
  padding: 5px 10px; /* Adjust padding */
  cursor: pointer;
}

.btn-view {
  color: #2196f3;
  font-size: 16px;
}

.btn-delete {
  color: #f44336;
  font-size: 16px;

}

.btn-container {
  display: inline-flex; /* Flex container for the buttons */
  gap: 5px; /* Space between the buttons */
}

.btn-edit {
  background-color: #ff9800;
  color: #fff;
}



.btn:hover {
  transform: scale(1.05);
}



.btn-edit:hover {
  background-color: #f57c00;
}



/* Responsive Styles */
@media (max-width: 768px) {
  .orders-table th,
  .orders-table td {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 8px; /* Adjust padding for smaller screens */
  }

  .btn {
    font-size: 12px; /* Adjust button font size for smaller screens */
    padding: 4px 8px; /* Adjust button padding for smaller screens */
  }

  .orders-page {
    padding: 10px;
  }
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.pagination-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.pagination-button.active {
  background-color: #0056b3;
  font-weight: bold;
}

.pagination-button:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.pagination-button[disabled] {
  pointer-events: none;
}

.pagination-button.ellipsis {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: default;
}


.details-and-image10 {
  display: flex; /* Flex layout to align details and image */
  justify-content: space-between; /* Space between details and image */
  align-items: center; /* Center items vertically */
}

.product-info10 {
  flex: 1; /* Allow the details to take available space */
  margin-right: 20px; /* Space between details and image */
}

.product-image10 {
  width: 150px;
  height: 150px; /* Maintain aspect ratio */
  object-fit: cover;
}

.modal-close-button10 {
  position: absolute; /* Position the button absolutely within the modal */
  top: 15px; /* Adjust as needed */
  right: 15px; /* Adjust as needed */
  background: transparent; /* No background */
  border: none; /* No border */
  font-size: 24px; /* Size of the icon */
  cursor: pointer; /* Pointer cursor on hover */
  color: #333; /* Color of the icon */
  z-index: 1000; /* Ensure it is above other elements */
}

.modal-close-button10:hover {
  color: #e74c3c; /* Change color on hover for feedback */
}


/* Modal styles */
.custom-modal10 {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  max-width: 550px;
  margin: auto;
  position: relative;
  margin-top: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  zoom: 0.9;
  z-index: 9999;
}


/* Details layout */
.order-details10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.details-and-image10 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.product-info10 {
  flex: 1;
  margin-right: 20px;
}



.info-row {
  flex: 1 1 45%; /* Take up to 45% of width, adjust as necessary */
  display: flex;
  justify-content: space-between; /* Space out label and value */
  padding: 3px 0; /* Vertical padding */
}

.info-label {
  font-weight: bold;
  width: 150px; /* Adjust width as needed for alignment */
}

.info-value {
  flex: 1;
  text-align: left;
  margin-left: 20px; /* Adjust margin for spacing */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .custom-modal10 {
    max-width: 90%;
    margin-top: 20px; /* Reduced top margin for smaller screens */
    padding: 15px; /* Reduced padding for better fit */
  }

  .details-and-image10 {
    flex-direction: column;
    align-items: center;
  }

  .product-info10 {
    flex: 1;
    margin-right: 10px;
  }

  .product-image10 {
    width: 100px; /* Adjusted image width for smaller screens */
    margin-bottom: 15px; /* Added margin for spacing */
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-label {
    font-weight: bold;
  width: 150px; /* Adjust width as needed for alignment */
  }

  .info-value {
    margin-left: 0; /* Reset margin for smaller screens */
    padding-left: 20px; /* Added padding for spacing */
  }
}
.statuss{
  color: #2c2c2e;
}