.slidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #001a35;
  color: #333;
  transition: width 0.4s ease, transform 0.4s ease;
  overflow: auto;
  z-index: 1001;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

}

.sidebar {
  display: flex;
  flex-direction: column;
}



.submenu.active {
  display: block; /* Show the active submenu */
  position: relative;
  top: 0;
}

.sidebar .submenu.active {
  order: -1; /* Make sure the active submenu is at the top */
}

.slidebar.collapsed {
  width: 90px;
}

.slidebar-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #001a35;
  white-space: nowrap;
  /* border-bottom:1px solid #afafaf; */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.slidebar-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-btn {
  font-size: 24px;
  background: none;
  border: none;
  color: rgb(82, 81, 81);
  cursor: pointer;
  top: 15px;
}

.logo-img {
  max-width: 44px;
  margin-left: auto;
  margin-right: auto;
}

.slidebar-menu {
  list-style: none;
  padding: 0;
}

.slidebar-menu li {
  padding: 10px;
  color: white;
}

.slidebar-menu li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.menu-icon {
  font-size: 20px;
  margin-right: 15px;
}

.slidebar.collapsed .menu-icon {
  margin-left: 10px;
  margin-right: 0; /* Remove right margin when collapsed */
}

.submenu {
  cursor: pointer;
}

.submenu.open .submenu-items {
  display: block;
}

.submenu-items {
  list-style: none;
  padding-left: 20px;
  display: none;
}

/* .submenu-items li {
  padding: 10px 0;
} */

.submenu-items li a {
  color: white;
}

.submenu-icon {
  margin-left: auto;
}

.menu-btn {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: none; /* Hide the button when sidebar is open */
}

.slidebar.collapsed + .menu-btn {
  display: block; /* Show the button when sidebar is collapsed */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .slidebar {
    width: 200px;
  }

  .slidebar.collapsed {
    width: 90px;
  }

  .menu-btn {
    top: 10px;
    left: 10px;
  }

  .close-btn {
    font-size: 20px;
    background: none;
    border: none;
    color: rgb(82, 81, 81);
    cursor: pointer;
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .slidebar {
    width: 65%;
  }

  .slidebar.collapsed {
    width: 80px;
  }
}

/* Custom scrollbar styling */
.slidebar::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.slidebar::-webkit-scrollbar-thumb {
  background-color: #3498db; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges for the thumb */
  border: 2px solid #001a35; /* Border around the thumb */
}

.slidebar::-webkit-scrollbar-track {
  background-color: #001a35; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded edges for the track */
}

.hamburger-icon{
  color: black;
}
/* Link styles */
.slidebar-menu a {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #ecf0f1; /* Light text color */
  text-decoration: none;
  transition: color 0.3s; /* Removed background color transition */
}

/* Active link styles */
.slidebar-menu a.active {
  color: #fff; /* Active link text color when sidebar is open */
  background-color: #2980b9; /* Active link background color when open */
}

/* Hover styles */
.slidebar-menu a:hover {
  background-color: #3498db; /* Hover color */
  color: #fff; /* Hover text color */
}

/* Menu icon styles */
.menu-icon {
  margin-right: 10px;
}

/* Submenu styles */
.submenu {
  padding-left: 15px; /* Indentation for submenus */
}

/* Submenu item styles */
.submenu-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Submenu item link styles */
.submenu-items a {
  padding: 5px 15px; /* Indentation for submenu links */
}

/* Submenu item hover styles */
.submenu-items a:hover {
  background-color: #2980b9; /* Hover color for submenu items */
}

/* Hamburger icon styles */
.hamburger-icon {
  font-size: 24px;
  color: #ecf0f1; /* Icon color */
  cursor: pointer;
  margin: 10px; /* Margin for better clickability */
}

/* Close button styles */
.close-btn {
  background: none;
  border: none;
  color: #ecf0f1;
  cursor: pointer;
  font-size: 20px;
}

/* When sidebar is collapsed */
.collapsed .slidebar-menu li a {
  color: white; /* Default color when collapsed */
}

/* Active link color when sidebar is collapsed */
.collapsed .slidebar-menu li a.active {
  color: #3498db; /* Change this to your preferred active color for icons when collapsed */
  background-color: transparent; /* Remove background color when collapsed */
}

/* Ensure the active link doesn't change background color when collapsed */
.collapsed .slidebar-menu a.active {
  background-color: transparent; /* Remove background color when sidebar is collapsed */
}
