
.ad-product-form {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 8px;
  width: auto; /* Expands based on content */
  max-width: 100%;
  min-width: 350px; /* Prevents it from being too small */
  flex-wrap: wrap; /* Allows wrapping in smaller screens */
  /* background: #f9f9f9; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid #ddd; */
}

.adminmodal {
  position: relative;
  padding: 20px;
}

.adminmodal-header {
  text-align: center;
  margin-bottom: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  color: red;
}


/* Ensure inputs expand properly */
.ad-form-input {
  flex: 1; /* Allows elements to take equal space */
  min-width: 180px; /* Prevents elements from shrinking too much */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  background: #fff;
}

.ad-form-btn {
  background: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  transition: background 0.3s, transform 0.2s;
}

.ad-form-btn:hover {
  background: #218838;
  transform: scale(1.05);
}

/* Responsive Layout */
@media (max-width: 600px) {
  .ad-product-form {
    flex-direction: column; /* Stack elements vertically */
    width: 100%;
    margin-left: -22px;
  }
  
  .ad-form-input,
  .ad-form-btn {
    width: 80%;
  }
}

.pc-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.pc-product-image-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust size as per requirement */
  height: 200px;
  overflow: hidden;
}

.pc-image-card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pc-product-brand {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
}

.pc-product-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures all images have the same size and aspect ratio */
}

.pc-edit-image-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.pc-edit-image-btn:hover {
  background-color: #45a049;
}

.pc-edit-image-btn:focus {
  outline: none;
}


.offer-ad-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for multiple ads */
  justify-content: center; /* Center the ads in the container */
  gap: 20px; /* Space between the ads */
}
.banner-container {
  max-width: 880px; /* Max width of the banner */
  width: 100%; /* Full width on smaller screens */
  height: 280px; /* Fixed height, can adjust for smaller screens if needed */
  background-size: 10px 10px; /* Adjust size for spacing of slashes */
  border: none; /* Remove regular border */
  margin-top: 20px; /* Add space between products and banner */
  display: flex; /* Center the image inside the container */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  flex-direction: column; /* Arrange children in a column */
  position: relative; /* Position relative for pseudo-elements */
  padding: 10px; /* Add some padding for smaller screens */
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.banner-image7{

  width: 875px;
  margin-top: 10px;
  height: 275px;
}
.potrait-imagee{
  width: 230px;
  height: 300px;
  margin-top: 10px;
}
/* Optional: You can add a pseudo-element for added styling if needed */
.banner-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed #000; /* Add dashed border inside */
  pointer-events: none; /* Allows clicks to pass through */
}

.laptops-cardd-input:focus,
.filee-input:focus
 {
  border-color: #007bff !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important;
  outline: none !important;
}

.laptops-cardd-input:hover,
.filee-input:hover,
.filee-inputt:hover
 {
  border-color: #0056b3 !important;
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6) !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-container {
    height: 300px; /* Adjust height for smaller screens */
    padding: 5px; /* Adjust padding for smaller screens */
  }
  .offer-ad {
    width: 100%;
    height: auto;
  }
  .banner-image7{

    width: 100%;
    margin-top: 10px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .offer-ad {
    width: 100%;
    height: auto;
  }

  .banner-image7{

    width: 100%;
    margin-top: 10px;
    height: auto;
  }
  .banner-container {
    height: 250px; /* Further adjust height for mobile devices */
    padding: 5px; /* Adjust padding for mobile */
  }
}
/* Title styling */
.banner-title {
  margin-bottom: 30px; /* Space below the title */
  font-size: 20px; /* Font size for the title */
  font-weight: bold; /* Bold font weight */
  color: #333; /* Color for the title text */
  text-align: center; /* Center align the title */
}

.potrait-container {
  width: 300px; /* Width of the potrait */
  height: 350px; /* Height of the potrait */
  background-size: 10px 10px; /* Adjust size for spacing of slashes */
  border: none; /* Remove regular border */
  margin-top: 20px; /* Add space between products and potrait */
  display: flex; /* Center the image inside the container */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  flex-direction: column; /* Arrange children in a column */
  position: relative; /* Position relative for pseudo-elements */
  padding: 10px;
}

/* Optional: You can add a pseudo-element for added styling if needed */
.potrait-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px dashed #000; /* Add dashed border inside */
  pointer-events: none; /* Allows clicks to pass through */
}
/* Title styling */
.potrait-title {
  margin-bottom: 30px; /* Space below the title */
  font-size: 20px; /* Font size for the title */
  font-weight: bold; /* Bold font weight */
  color: #333; /* Color for the title text */
  text-align: center; /* Center align the title */
}
.input-groupp {
  display: flex; /* Use flexbox for the input group */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between elements */
}


.input-grouppp {
  display: flex; /* Use flexbox to align children */
  flex-direction: column; /* Arrange inputs in a column */
  width: 100%; /* Ensure inputs take full width of container */
  align-items: center; /* Center align inputs */
  gap: 10px;
}

.portrait-container {
  width: 300px; /* Default width */
  height: 350px; /* Default height */
  background-size: 10px 10px; /* Background size */
  border: none; /* Remove border */
  margin-top: 20px; /* Space above */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  flex-direction: column; /* Arrange children in a column */
  position: relative; /* Relative positioning */
}
/* Responsive adjustments */
@media (max-width: 768px) {

  .portrait-container {
    width: 60%; /* Change width to 90% for smaller screens */
    height: auto; /* Adjust height to auto for flexibility */
    margin: 10px; /* Add margin for spacing */
}

  .input-groupp {
    flex-direction: column; /* Stack inputs in a column for smaller screens */
    align-items: stretch; /* Ensure inputs take full width */
  }
  .input-grouppp {
    flex-direction: column; /* Stack inputs in a column for smaller screens */
    align-items: stretch; /* Ensure inputs take full width */
  }
}
.laptops-cardd-input {
  padding: 10px; /* Inner padding for input field */
  border: 1px solid #ccc; /* Border for input */
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 16px; /* Font size for better readability */
  flex: 1; /* Allow input field to grow */
}

.filee-input {
  padding: 10px; /* Inner padding for file input */
  border: 1px solid #ccc; /* Border for file input */
  border-radius: 4px; /* Slightly rounded corners */
  flex: 1; /* Allow file input to grow */
}
.filee-inputt {
  padding: 10px; /* Inner padding for file input */
  border: 1px solid #ccc; /* Border for file input */
  border-radius: 4px; /* Slightly rounded corners */
  flex: 1; /* Allow file input to grow */
}

.laptops-add-btn {
  padding: 10px 15px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  background-color: #007bff; /* Button background color */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
}

.laptops-add-btn:hover {
  background-color: #0056b3; /* Darker background on hover */
}

.selected-image {
  margin-top: 10px; /* Space above the selected image text */
  font-size: 14px; /* Font size for the selected image text */
  color: #333; /* Color for the text */
}

.laptops-add-btn {
  padding: 10px 15px; /* Button padding */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  background-color: #007bff; /* Button background color */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
  margin-top: -3px;
}

.laptops-add-btn:hover {
  background-color: #0056b3; /* Darker background on hover */
}

.selected-image {
  margin-top: 10px; /* Space above the selected image text */
  font-size: 14px; /* Font size for the selected image text */
  color: #333; /* Color for the text */
}
  
.single-ad-container {
  position: relative;
  width: 100%;
  max-width: 400px; /* Maximum width for each ad container */
  margin: 10px;
  overflow: hidden; /* Ensures the content doesn't overflow */
}

.image-wrapper {
  position: relative;
  display: flex; /* Use flexbox to position product image and details side by side */
  align-items: center; /* Center vertically */
}

.offer-ad {
  max-width: 100%; /* Make the image responsive */
  max-height: 100%; /* Limit the height of the image */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  display: block; /* Remove any inline spacing around the image */
}

.offer-tag {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f39c12;
  color: white;
  padding: 15px 11px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  z-index: 2;
}

.brand-name {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #36b4e6;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  z-index: 2;
}

.laptops-edit-btnn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  z-index: 2;
}

.product-overlay {
  display: flex; /* Use flexbox to layout product image and details */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space out the image and details */
  position: absolute; /* Overlay within the offerAd */
  top: 17%; /* Position the overlay */
  left: 5%; /* Position the overlay */
  right: 5%; /* Position the overlay */
  color: white; /* Text color for visibility */
  border-radius: 8px; /* Rounded corners for overlay */
  padding: 10px; /* Padding around the text */
}

.ad-imagee {
  width: 130px; /* Set size for product image */
  height: 130px; /* Set size for product image */
  margin-right: 10px; /* Space between image and text */
  border-radius: 8px; /* Rounded corners for the product image */
}

/* Image actions styles */
.image-action {
  position: absolute; /* Position it absolutely within the product image wrapper */
  top: -25px; /* Distance from the top of the image */
  right: 25px; /* Distance from the right of the image */
  gap: 5px; /* Space between icons */
  z-index: 1; /* Ensure it stays on top of other elements */
}

.image-action span {
  cursor: pointer; /* Pointer cursor for clickable icons */
  color: white; /* Icon color for visibility */
  font-size: 16px; /* Adjust icon size */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect for icons */
.image-action span:hover {
  color: #f39c12; /* Change color on hover (orange) */
}

.text-details {
  flex: 1; /* Take remaining space for text details */
  text-align: left; /* Left align text */
}

.text-details h3 {
  font-size: 23px; /* Adjust font size */
  margin-bottom: 5px; /* Space below the title */
  font-family: 'Dancing Script', cursive; /* Graceful handwritten font */
  text-align: center;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-details p {
  font-size: 14px; /* Adjust font size */
  line-height: 1.4; /* Space between lines */
  margin: 0; /* Remove default margins */
  font-family: 'calibri', cursive; /* Delicate, elegant script for paragraph */
  margin-bottom: 5px;
  /* white-space: nowrap; */
  text-align: center;

  display: -webkit-box;             /* Fallback for old browsers */
  -webkit-box-orient: vertical;     /* Enable vertical layout */
  overflow: hidden;                  /* Hide overflow */
  text-overflow: ellipsis;          /* Show ellipsis for overflow text */
  height: 4em;                    /* Set height for 4 lines (1.4em * 4) */
  max-height: 4em;                /* Ensure max-height matches */

}

.laptops-edit-btnn {
  background-color: #2ecc71;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}

.laptops-edit-btnn:hover {
  background-color: #27ae60;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .single-ad-container {
    max-width: 100%;
  }

  .ad-imagee {
    width: 80px; /* Smaller image size on mobile */
    height: 80px;
  }

  .text-details h3 {
    font-size: 16px; /* Slightly smaller title size */
  }

  .text-details p {
    font-size: 12px; /* Smaller description */
  }

  .laptops-edit-btnn {
    font-size: 12px; /* Adjust button size */
    padding: 6px 10px;
  }
}


.product-image-wrapper {
  position: relative; /* Position relative to allow absolute positioning of child elements */
  max-width: 400px; /* Maximum width for the wrapper */
  max-height: 200px; /* Maximum height for the wrapper */
  width: 100%; /* Make it responsive */
  height: 200; /* Allow height to adjust based on content */
  margin: 0 auto; /* Center the wrapper */
}

.fatimes {
  position: absolute;
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  background: transparent;
  border: none;
  cursor: pointer;
  color: #999; /* Default color for the close button */
  transition: color 0.3s; /* Smooth transition for color change */
}

.fatimes:hover {
  color: black; /* Change color on hover */
}

.coupon-title {
  font-size: 1.5em;
  margin-bottom: 15px; /* Space below title */
  color: #333; /* Dark color for title */
}
 .faedit{
    margin-left: 7px;
  }
.coupons-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.coupon-item {
  padding: 10px 0; /* Add vertical padding to items */
  border-bottom: 1px solid #e0e0e0; /* Light border between items */
}

.coupon-code {
  font-weight: bold; /* Make coupon code bold */
  color: #4CAF50; /* Green color for coupon codes */
}

.expiry-date {
  color: #888; /* Grey color for expiry dates */
}