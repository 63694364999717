/* Form Container */
.form-container {
    max-width: 500px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Form Header */
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
  }
  
  /* Input Fields */
  .form-container input,
  .form-container textarea,
  .form-container select {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    transition: border 0.3s;
  }
  
  /* Input Focus Effect */
  .form-container input:focus,
  .form-container textarea:focus,
  .form-container select:focus {
    border-color: #4a90e2;
    outline: none;
  }
  
  /* Textarea */
  .form-container textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  /* Submit Button */
  .form-container button {
    background-color: #4a90e2;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
  }
  
  /* Button Hover Effect */
  .form-container button:hover {
    background-color: #357ab7;
  }
  
  /* Error or Success Message */
  .message {
    text-align: center;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  