.button-with-image {
  display: flex;
  align-items: center;
  border: 1px solid #3498db; 
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%); /* Gradient from purple to blue */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding around the text and image */
  font-size: 16px; /* Text size */
  font-weight: bold; /* Text weight */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  text-transform: uppercase; /* Uppercase text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.button-with-image:hover {
  background-color: #2980b9; /* Darker background on hover */
  border-color: #2980b9; /* Darker border on hover */
  transform: translateY(-2px); /* Slight upward movement on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.button-with-image:active {
  transform: translateY(0); /* Remove upward movement on click */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Return to normal shadow */
}

.button-with-image img {
  margin-left: 12px; /* Space between image and text */
  transition: transform 0.3s ease; /* Smooth image transition */
}


/* Container for the product list */
.product-list3 {
  padding: 20px;
  text-align: center;
  background-color: #f4f4f4; /* Light background for contrast */
}

/* Title styling */
.title {
  text-align: center; /* Center the text horizontally */
  font-size: 26px; /* Adjust the font size as needed */
  margin: 0 auto; /* Center the element itself if it's a block element */
  color: #333; /* Adjust the text color as needed */
}

.products {
  display: flex;
  flex-direction: column; /* Changed to column for vertical stacking */
  flex-wrap: wrap; /* Allow products to wrap to the next line */
  gap: 20px; /* Space between product rows */
}




  
/* .product-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  } */
  .product-row {
    display: flex; /* Align items in a row */
    align-items: center; /* Vertically center items */
    margin-bottom: 20px; /* Space between rows */
  }
  
  .product-section {
    display: flex;
    flex-direction: column; /* Stack title and product row vertically */
    margin-top: 20px; /* Add some space between sections */
  }
  
  .product-items {
    display: flex; /* Align products in a row */
    flex-wrap: wrap; /* Allows products to wrap to the next line if necessary */
    gap: 10px; /* Space between products */
    flex-grow: 1; /* Ensures the product section grows to fill remaining space */
  }
  
  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  
  .category-title {
    font-size: 24px; /* Adjust title size */
    font-weight: bold; /* Make the title bold */
    color: #fff; /* Change text color to white for better contrast */
    margin-right: 20px; /* Space between title and products */
    white-space: nowrap; /* Prevents title from wrapping */
    width: 200px; /* Fixed width for title to align properly */
    padding: 10px; /* Add padding for better spacing */
    background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%); /* Gradient from purple to blue */
    border-radius: 5px; /* Rounded corners for the title background */
  }
  
  /* Product styling */
  .product {
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    width: calc(20% - 10px); /* Adjust width of products */
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 405px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative; /* Needed for absolute positioning of children */
    background-color: white;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .product-row {
      flex-direction: column; /* Stack items vertically in responsive view */
    }
  
    .product-actual-price {
      display: flex;
      flex-direction: column; /* Stack price details vertically */
      margin-top: 1px; /* Space above price details */
      text-align: center; /* Center align for better presentation */
    }
    .category-title {
      margin-bottom: 10px; /* Space between title and products */
      width: auto; /* Auto width to fit the screen */
    }
  
    .product {
      width: calc(50% - 10px); /* 2 products per row */
    }
  }
  
  @media (max-width: 480px) {
    .product {
      width: calc(100% - 10px); /* 1 product per row */
    }
    
  }
  
  .product-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #000000; /* Red background for label */
    color: #fff; /* White text */
    padding: 5px 10px;
    border-radius: 5px;
  }

/* Favorite icon styling */
.favorite-icon {
  position: absolute; /* Position absolutely within the product card */
  top: 10px; /* Adjust position as needed */
  right: 10px; /* Adjust position as needed */
  cursor: pointer;
  color: #ccc; /* Default color */
  font-size: 1.5rem; /* Adjust size as needed */
  transition: color 0.3s ease; /* Smooth color transition */
  z-index: 1; /* Ensure it's on top of other elements */
}

/* When the product is favorited */
.favorite-icon.filled {
  color: red; /* Color when favorited */
}

/* Product image styling */
.product img {
  max-width: 100%;
  height: 150px; /* Fixed height for consistency */
  object-fit: cover; /* Cover the area */
  border-radius: 8px; /* Rounded corners for images */
  margin-bottom: 10px; /* Margin below the image */
}

/* Product name styling */
.product h3 {
  font-size: 1.125rem; /* Font size for product name */
  margin-bottom: 8px; /* Margin below the name */
  color: #333; /* Text color */
}

/* Responsive styles */
@media (max-width: 768px) {
  .product {
    width: calc(50% - 10px); /* 2 products per row */
  }
  
  .category-title {
    margin-bottom: 15px; /* Adjust space below title for smaller screens */
  }
}

@media (max-width: 480px) {
  .product {
    width: calc(100% - 10px); /* 1 product per row */
  }
}

/* Product features and price styling */
.product-actual-pricee {
  font-size: 14px; /* Font size for product details */
  color: #555; /* Slightly lighter text color */
  margin-bottom: 12px; /* Margin below the text */
  text-align: center; /* Center text alignment */
}

/* Hover effect for product cards */
.product:hover {
  transform: translateY(-5px); /* Lift the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}
/* Responsive Styles */
@media (max-width: 1200px) {
  .product {
    width: calc(25% - 20px); /* 4 products in a row */
  }
}

@media (max-width: 992px) {
  .product-row {
    gap: 10px;
  }

  .product {
    width: calc(33.33% - 20px); /* 3 products in a row */
  }
}

@media (max-width: 768px) {
  .product-row {
    gap: 10px;
  }

  .product {
    width: calc(50% - 5px); /* 2 products in a row */
  }
}

@media (max-width: 576px) {
  .product-row {
    gap: 10px;
  }

  .product {
    width: calc(50% - 5px); /* 2 products in a row for smaller devices */
    margin: 5px; /* Smaller margin for better spacing */
  }

  .product-description {
    font-size: 1rem; /* Adjust product description font size */
  }

  .product img {
    max-width: 100%; /* Ensure images are responsive */
  }

  .add-to-cartt {
    width: 100%; /* Make the button take the full width */
    padding: 8px; /* Adequate padding for touch devices */
    font-size: .9rem;
  }

  .favorite-icon {
    font-size: 1.2rem; /* Adjust favorite icon size */
  }

  .product-label {
    font-size: 0.8rem; /* Smaller label size */
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .product-row {
    gap: 10px;
  }

  .product {
    width: calc(50% - 5px ); /* Maintain 2 products in a row on smaller devices */
    margin: 5px 0; /* Adjust margin */
    height: auto;
    gap: 10px;
  }

  .title {
    font-size: 1.5rem; /* Smaller title size for mobile */
  }

  .product-description {
    font-size: 0.9rem; /* Smaller text for product descriptions */
  }
}

.product-descriptionn {
  display: -webkit-box; /* Required for WebKit-based browsers */
  /* display: box;                     */
  -webkit-box-orient: vertical; /* Required for WebKit-based browsers */
  box-orient: vertical; /* Fallback for other browsers */
  overflow: hidden; /* Hide overflow content */
  -webkit-line-clamp: 2; /* Limit to 2 lines in WebKit-based browsers */
  line-clamp: 2; /* Future proofing for standard implementation */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}
.custom-slider-subtitle {
  display: -webkit-box; /* Required for WebKit-based browsers */
  /* display: box;                     */
  -webkit-box-orient: vertical; /* Required for WebKit-based browsers */
  box-orient: vertical; /* Fallback for other browsers */
  overflow: hidden; /* Hide overflow content */
  -webkit-line-clamp: 2; /* Limit to 2 lines in WebKit-based browsers */
  line-clamp: 2; /* Future proofing for standard implementation */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}

.product-namee {
  display: -webkit-box; /* Required for WebKit-based browsers */
  /* display: box;                      */
  -webkit-box-orient: vertical; /* Required for WebKit-based browsers */
  box-orient: vertical; /* Fallback for other browsers */
  overflow: hidden; /* Hide overflow content */
  -webkit-line-clamp: 2; /* Limit to 2 lines in WebKit-based browsers */
  line-clamp: 2; /* Future proofing for standard implementation */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}
.product-subtitle {
  display: -webkit-box; /* Required for WebKit-based browsers */
  /* display: box;                      */
  -webkit-box-orient: vertical; /* Required for WebKit-based browsers */
  box-orient: vertical; /* Fallback for other browsers */
  overflow: hidden; /* Hide overflow content */
  -webkit-line-clamp: 2; /* Limit to 2 lines in WebKit-based browsers */
  line-clamp: 2; /* Future proofing for standard implementation */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}
.product-name {
  font-weight: 500;
}

.product-subtitle{

  color: grey;
  font-size: 12px;
}


.add-to-cartt {
  background-color: #ff6600;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  /* margin-bottom: 1px; */
}

.add-to-cartt:hover {
  background-color: #e65500;
}
