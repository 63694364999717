.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.staff-input.password-hidden {
  -webkit-text-security: disc; /* Mimic password dots */
}

/* Radio button wrapper */
.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Label for radio buttons */
.radio-label {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

/* Radio button styles */
.radio-input {
  width: 18px;
  height: 18px;
  accent-color: #4CAF50; /* Green for checked state */
  cursor: pointer;
  outline: none;
}



/* Container styling */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom right, #add8e6, #ffffff);
}

.login-container {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.9); /* Add a glowing effect */
  background-color: rgba(0, 0, 0, 0.8); /* Medium black with transparency */
  position: relative;
  z-index: 1000;
  /* border: 1px solid #whit */
}

/* SweetAlert styling to ensure it does not affect the form */
.swal2-container {
  z-index: 1050 !important;
}

.login-header {
  text-align: center;
  margin-bottom: 20px;
}

.login-header h1 {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.login-header p {
  font-size: 16px;
  color: white;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.admin-label {
  color: white;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: transparent;
  color: white;
}


.staff-input:focus {
  border-color: #007bff !important; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a glowing effect */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.staff-input:hover {
border-color: #0056b3 !important; 
box-shadow: 0 0 6px rgba(0, 86, 179, 0.6) !important; 
}
.button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007BFF;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

.password-wrapper {
  position: relative;
}

.password-wrapper input {
  width: 100%;
  padding-right: 40px; /* Adjust for eye icon space */
}

.eye-icon {
  position: absolute;
  right: 15px;
  top: 60%;
  transform: translateY(-50%);
  cursor: pointer;
  color: white;
}
