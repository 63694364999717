.about-page {
    font-family: 'calibri', sans-serif;
  }
  
  .about-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; /* Allows content to stack on small screens */
  }
  
  .image-container {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .about-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .image-container:hover .about-image {
    transform: scale(1.05); /* Slightly zooms in the image on hover */
  }
  
  .text-container {
    flex: 2;
    max-width: 600px;
  }
  
  .about-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  .about-paragraph {
    font-size: 1.0em;
    line-height: 1.6;
    color: #555;
    text-align: justify;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
      align-items: center;
    }
  
    .image-container {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .text-container {
      max-width: 100%;
    }
  
    .about-title {
      font-size: 2em;
    }
  
    .about-paragraph {
      font-size: 1em;
    }
  }