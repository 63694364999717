/* General container for the customers page */
.customers-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    /* background-color: #f8f9fa; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: 250px;
    margin-top: 70px;
  }
  
  /* Page title styling */
  .page-titlee {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }
  
  /* Customers section styling */
  .customers-section {
    margin-bottom: 40px;
  }
  
  /* Wrapper for the table to allow scrolling */
  .table-wrapper {
    overflow-x: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  
  /* Table styling */
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    min-width: 800px; /* Increased width for better display */
    text-align: left;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .styled-table th, 
  .styled-table td {
      border: 1px solid #ddd;
      padding: 12px;
      text-align: center;
  }
  
  /* Header row styles */
  .styled-table th {
      background-color: #f2f2f2;
      font-weight: bold;
      color: #333;
  }
  
  /* Table cell styling */
  .styled-table td {
    padding: 14px 20px;
    border-bottom: 1px solid #ddd;
    color: #555;
    text-align: center;
  }
  
  /* Alternate row colors */
  .styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Hover effects */
  .styled-table tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effects for table data */
  .styled-table tr:hover td {
    color: #007bff;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 992px) {
    .customers-container {
      padding: 10px;
      margin-left: 0;
      margin-right: 0;
    }
  
    .page-title {
      font-size: 2rem;
    }
  
    .styled-table th, 
    .styled-table td {
      padding: 12px 15px;
      font-size: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .page-title {
      font-size: 1.8rem;
    }
  
    .styled-table th, 
    .styled-table td {
      padding: 10px 12px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .page-title {
      font-size: 1.5rem;
    }
  
    .styled-table th, 
    .styled-table td {
      padding: 8px 10px;
      font-size: 12px;
    }
  }

  .user-details-modal {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 500px;
    margin: auto;
    margin-top: 150px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    max-height: 450px; /* Fixed height for modal */
    overflow-y: auto; /* Enables vertical scrolling */
  }
  
  .view-button {
    background-color: #3085d6;
    color: white;
    border: none;
    padding: 8px 12px; /* Increased padding for better click area */
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Increased font size */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Adding transitions */
  }
  
  .view-button:hover {
    background-color: #2673a4;
    transform: scale(1.05); /* Slight scaling on hover for effect */
  }
  
  .view-button:active {
    transform: scale(0.95); /* Slight shrink on click for feedback */
  }
  
  /* Additional styles for modal content */
  .user-details-modal h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333; /* Darker title color */
  }
  
  .user-details-modal p {
    margin: 5px 0; /* Spacing between paragraphs */
    font-size: 16px; /* Consistent font size for details */
    line-height: 1.5; /* Better readability */
  }
  
  /* Add a close button style */
  .user-details-modal button {
    background-color: #f44336; /* Red background for close button */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for close button */
  }
  
  .user-details-modal button:hover {
    background-color: #d32f2f; /* Darker red on hover */
  }
  