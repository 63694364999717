/* Container for the dropdown menu */
.dropdownnn-container {
    position: absolute;
    top: 57px; /* Adjust based on your layout */
    right: 8px;
    width: 200px; /* Adjust width as needed */
    background-color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 1002; /* Ensure it is above other elements */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
    display: flex;
    flex-direction: column;
    max-height: 310px; /* Set a max height for the dropdown */
  border: 1px solid #ccc;

}

/* Dropdown menu content styles */
.dropdownnn-content {
    display: flex;
    flex-direction: column;
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

/* Dropdown menu items */
.dropdownnn-content a {
    display: flex;
    align-items: center;
    padding: 12px 25px;
    text-decoration: none;
    color: #333;
    font-size: 14px;
    transition: background-color 0.3s;
}

.dropdownnn-content i, .dropdownnn-content svg {
    margin-right: 10px; /* Adjust space between icon and text */
    font-size: 16px; /* Adjust icon size */
}

.dropdownnn-content a:hover {
    background-color: #f0f0f0;
}

/* Remove border from last item */
.dropdownnn-content a:last-child {
    border-bottom: none;
}



@media (max-width: 768px) {
    .dropdownnn-container {
        position: absolute;
        top: 40px; /* Adjust based on your layout */
        right: 63px;
        width: 200px; /* Adjust width as needed */
        background-color: #fff;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        z-index: 1003; /* Ensure it is above other elements */
        overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
        display: flex;
        flex-direction: column;
        max-height: 310px; /* Set a max height for the dropdown */
      border: 1px solid #ccc;
    
    }
}

