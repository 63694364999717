.btn-container {
    display: flex;
    gap: 15px; /* Space between the two buttons */
    justify-content: flex-start; /* Align buttons to the left */
    margin-top: 2px;
  }
  
  .addToCart,
  .buy-now {
    padding: 11px 18px;
    font-size: 11px;
    font-weight: bold;
    /* border-radius: 5px; */
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    background-size: 200% 200%; /* Makes the gradient fill the button */
  }
  
  .addToCart {
    background: linear-gradient(45deg, #4caf50, #81c784); /* Green gradient */
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .addToCart:hover {
    background: linear-gradient(135deg, #4caf50, #81c784); /* Hover with rotated gradient */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .addToCart:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(72, 199, 106, 0.6);
  }
  
  .buy-now {
    background: linear-gradient(45deg, #ff9800, #ffb74d); /* Orange gradient */
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .buy-now:hover {
    background: linear-gradient(135deg, #ff9800, #ffb74d); /* Hover with rotated gradient */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .buy-now:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 152, 0, 0.6);
  }
  
  .buy-now span {
    margin-left: 10px;
    font-size: 18px;
    transform: translateY(2px);
  }
  

  
  button:disabled {
    background: #ccc;
    cursor: not-allowed;
    box-shadow: none;
  }
  
  @media (max-width: 768px) {
    .btn-container {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
      align-items: center;
    }
  
    .addToCart,
    .buy-now {
      width: 100%;
      margin-bottom: 10px; /* Space between buttons when stacked */
    }
  }
  
.no-products-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh; /* Adjust height to your preference */
  text-align: center;
  /* color: #555; */
  margin-left: 240px;
}

.no-products-message h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
    
.no-products-message p {
  font-size: 18px;
  /* color: #777; */
}


/* Computers Page Styles */
.computers-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    display: flex;
    flex: 1;
    flex-direction: row; /* Default layout */
}

.sidebar {
    width: 250px;
    padding: 20px;
    background-color: #f8f8f8;
    border-right: 1px solid #ddd;
    flex-shrink: 0; /* Prevent sidebar from shrinking */
}

.sidebar h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #333;
}

.price-filter {
    margin-top: 20px;
}

.product-list {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.product-card {
  position: relative;
  width: calc(25% - 20px); /* Ensures 4 cards in a row */
  height: 420px; /* Fixed height for uniformity */
  max-height: 420px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  text-align: center;
  /* border-radius: 8px; */
  margin-bottom: 15px; /* Space below each card */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distributes content evenly */
  padding: 15px;
  transition: transform 0.2s ease-in-out;
}



.product-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  /* margin: 1px 0; */
}

.product-subtitle2 {
  font-size: 13px;
  color: grey;
  /* margin: 2px; */
}

.product-price {
  font-size: 20px;
  color: #27ae60;
  font-weight: bold;
  margin-right: 5px;
}

/* .product-actual-price {
  font-size: 15px;
  color: red;
  text-decoration: line-through;
} */

.add-to-cart {
    background-color: #ff6600;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.add-to-cart:hover {
    background-color: #e65500;
}

.product-actions {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.favorite-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    color: #ccc; /* Light color for empty heart */
    transition: color 0.3s ease;
}

.favorite-icon.filled {
    color: red; /* Red color for filled heart */
}


/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
    .product-card {
        width: calc(33.33% - 20px); /* Fit 3 cards in a row for medium screens */
    }
}

@media (max-width: 768px) {
    .main-content {
        flex-direction: column; /* Stack sidebar and product list */
    }
    .no-products-message {
        margin-left: 0px;
        /* margin-top: 50px; */
    }
    .add-to-cart{
        width: 70%;
    }


    .sidebar {
        width: 100%; /* Full width on small screens */
        border-right: none; /* Remove border */
        border-bottom: 1px solid #ddd; /* Add border at bottom */
    }

    .product-card {
        width: calc(50% - 20px); /* Fit 2 cards in a row for small screens */
    }
}

@media (max-width: 480px) {
    .product-card {
        width: calc(50% - 10px); /* Fit 1 card in a row for very small screens */
    }
    
}

.product-description {
    display: -webkit-box;              /* Required for WebKit-based browsers */
    display: box;                       /* Fallback for other browsers */
    -webkit-box-orient: vertical;      /* Required for WebKit-based browsers */
    box-orient: vertical;              /* Fallback for other browsers */
    overflow: hidden;                   /* Hide overflow content */
    -webkit-line-clamp: 2;             /* Limit to 2 lines in WebKit-based browsers */
    line-clamp: 2;                     /* Future proofing for standard implementation */
    text-overflow: ellipsis;           /* Show ellipsis when text overflows */
  }
  
.product-name {
    display: -webkit-box;              /* Required for WebKit-based browsers */
    /* display: box;                        */
    -webkit-box-orient: vertical;      /* Required for WebKit-based browsers */
    box-orient: vertical;              /* Fallback for other browsers */
    overflow: hidden;                   /* Hide overflow content */
    -webkit-line-clamp: 2;             /* Limit to 2 lines in WebKit-based browsers */
    line-clamp: 2;                     /* Future proofing for standard implementation */
    text-overflow: ellipsis;           /* Show ellipsis when text overflows */
  }
  
.product-subtitle2 {
    display: -webkit-box;              /* Required for WebKit-based browsers */
    /* display: box;                        */
    -webkit-box-orient: vertical;      /* Required for WebKit-based browsers */
    box-orient: vertical;              /* Fallback for other browsers */
    overflow: hidden;                   /* Hide overflow content */
    -webkit-line-clamp: 2;             /* Limit to 2 lines in WebKit-based browsers */
    line-clamp: 2;                     /* Future proofing for standard implementation */
    text-overflow: ellipsis;           /* Show ellipsis when text overflows */
  }
  