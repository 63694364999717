/* .laptops-modal-form-container {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.laptops-modal-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px; /* Fixed width */
  height: 500px; /* Fixed height */
  position: relative;
  text-align: center;
  overflow: auto; /* Ensure arrows are contained within the modal */
}

.modal-content3 {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px; /* Fixed width */
  height: 440px; /* Fixed height */
  position: relative;
  text-align: center;
  overflow: auto; /* Ensure arrows are contained within the modal */
}



.laptops-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns for left and right sections */
  gap: 10px;
}

/* Left section styling */
.laptops-modal-left-section {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Space between each label-value pair */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.laptops-modal-left-section p {
  display: flex;
  justify-content: space-between; /* Space between label and value */
  font-size: 14px;
  margin: 5px 0;
  color: #555;
}

.laptops-modal-left-section strong {
  color: #333;
  flex: 1; /* Allow label to take available space */
  text-align: left;
}

.laptops-modal-left-section span {
  flex: 1; /* Allow value to take remaining space */
  text-align: left;
  font-weight: normal;
}

.laptops-modal-right-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

/* Media query for responsive view (small screens) */
@media (max-width: 768px) {
  .laptops-modal-content {
    grid-template-columns: 1fr; /* Stack the sections vertically */
  }

  /* Ensuring both sections still have full width on smaller screens */
  .laptops-modal-left-section,
  .laptops-modal-right-section {
    width: 100%;
  }
}

.upload-container, .frequently-buy {
  margin-top: 10px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-container .file-input {
  margin-top: 10px;
  display: block;
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.upload-button, .offer-submit-btn, .offer-delete-btn {
  color: #4CAF50;
  /* color: white; */
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.offer-edit-btn {
  background-color: #ff9800;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.offer-edit-btn:hover {
  background-color: #fb8c00;
}

.offer-edit-text {
  font-size: 14px;
  color: white;
}

.offer-edit-icon {
  font-size: 18px;
  color: white;
}

.laptops-product-coupon {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}

.laptops-product-coupon span {
  cursor: pointer;
}

.coupons-list {
  list-style: none;
  padding: 0;
}

.coupon-item {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 5px 0;
}

.coupon-item:hover {
  background-color: #f1f1f1;
}

.coupon-code, .expiry-date {
  font-weight: bold;
}

.frequently-buy-label {
  font-size: 16px;
  font-weight: bold;
}

.copy-icon {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
  color: rgb(1, 184, 168);
}


.frequently-buy-icon {
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
  color: rgb(1, 184, 168);
}

.frequently-buy-icon:hover {
  color: #00d6d6;
  transform: scale(1.2); /* Slightly enlarges the icon on hover */

}
.copy-icon:hover {
  color: #00d6d6;
  transform: scale(1.2); /* Slightly enlarges the icon on hover */

}

.freq-modal-content {
  background-color: white;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.freq-modal-close-btn {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.freq-modal-close-btn:hover {
  color: #f44336;
}

.freq-modal-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.freq-modal-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.freq-image-wrapper {
  width: 50px;
  height: 50px;
}

.freq-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.freq-item-details {
  flex-grow: 1;
  padding-left: 10px;
}

.freq-item-name {
  font-weight: bold;
}

.freq-item-price {
  color: #4CAF50;
  font-size: 14px;
}

.freq-item-checkbox {
  margin-left: 10px;
}

.view-details-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 8px 15px; /* Add padding for more space inside the button */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Vertically center the icon and text */
  gap: 10px; /* Space between the icon and text */
  font-size: 14px; /* Slightly larger font size for better readability */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
  margin: 10px;
}

/* Hover effect */
.view-details-btn:hover {
  background-color: #45a049; /* Slightly darker green on hover */
  transform: translateY(-2px); /* Small lift effect */
}

/* Focus effect */
.view-details-btn:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(72, 230, 103, 0.5); /* Green glow on focus */
}

/* Button container (to add space around the button) */
.button-container {
  margin: 20px; /* Add space around the button */
  display: flex;
  justify-content: center; /* Center button horizontally */
  align-items: center; /* Center button vertically */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .view-details-btn {
    font-size: 14px; /* Slightly smaller font size on mobile */
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }
}


.product-details-modal .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details-modal .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  position: relative;
  overflow-y: auto;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.product-info {
  margin-top: 20px;
}

.feature-item {
  margin: 5px 0;
}

.feature-key {
  font-weight: bold;
}

.feature-value {
  margin-left: 10px;
}


/* General container styling */
.laptops-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 900px;
  margin: auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  justify-content: space-between;
}

/* Left and Right sections */
.laptops-left-section,
.laptops-right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Labels and Inputs */
.laptops-label {
  font-weight: bold;
  /* margin-bottom: 1px; */
  display: block;
  font-size: 14px;
  color: #333;
}

.laptops-input,
.laptops-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}
.laptops-input:focus,
.laptops-textarea:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a glowing effect */
}

.laptops-input:hover,
.laptops-textarea:hover {
  border-color: #0056b3; 
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6); 
}



.laptops-textarea {
  resize: vertical;
  min-height: 50px;
}

/* File Input */
.laptops-file-input {
  border: none;
  padding: 5px;
  font-size: 14px;
}

/* Add button */
.laptops-add-btn {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
  align-self: left;
}

.laptops-add-btn:hover {
  background-color: #0056b3;
}



/* Modal Overlay */
.offer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey; /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure modal is above other content */
  padding: 20px;
}
/* Edit button */
.offer-edit-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #007bff;
  margin-top: 5px;
}

.offer-timer{
  margin-top: 5px;
}
.offer-edit-icon {
  margin-right: 8px;
}

.offer-edit-text {
  font-size: 1rem;
  margin-right: 8px;
  font-weight: 500;
}
/* Modal Content */
.offer-modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: modalIn 0.3s ease-in-out;
}

/* Close Button */
.offer-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  background: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.offer-close-btn:hover {
  color: red; /* Change color on hover */
}

/* Modal Title */
.offer-modal-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Form Labels */
.offer-label {
  font-size: 16px;
  margin-bottom: 8px;
  color: #555;
}

/* Form Inputs */
.offer-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.offer-input:focus {
  border-color: #007bff; /* Highlight input border on focus */
  outline: none;
}

/* Submit Button */
.offer-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.offer-submit-btn:hover {
  background-color: #0056b3; /* Darken on hover */
}

/* Delete Offer Button */
.offer-delete-btn {
  width: 100%;
  padding: 12px;
  background-color: #ff6b6b;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.offer-delete-btn:hover {
  background-color: #e60000; /* Darken on hover */
}

/* Animation for modal opening */
@keyframes modalIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}



.no-accessories-message {
  text-align: center;
  padding: 20px;
}

.add-accessories-btn {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.add-accessories-btn:hover {
  background-color: #45a049;
}

.frequently-buy {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  gap: 8px; /* Adds spacing between label and icon */
}

.frequently-buy-label {
  font-size: 16px; /* Adjust font size for the label */
  color: #333; /* Adjust color if needed */
}





.freq-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.freq-modal-content {
  background: white;
  border-radius: 8px;
  width: 400px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.freq-modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.freq-modal-title {
  font-size: 1.25rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.freq-modal-list {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.freq-modal-item {
  display: flex;
  align-items: center;
  gap: 15px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  justify-content: space-between; /* Ensures spacing between elements */

}

.freq-item-image {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
}

.freq-item-name {
  flex: 2;
  font-size: 1rem;
  font-weight: 500;
}

.freq-item-details {
  flex: 1; /* Occupies remaining space */
  display: flex;
  align-items: center; /* Aligns content vertically */
  justify-content: space-between; /* Spreads name and price evenly */
  gap: 10px; /* Adds spacing between name and price */
}
.freq-item-price {
  /* flex: 1; */
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.freq-item-checkbox {
  flex: 0;
  transform: scale(1.2);
  cursor: pointer;
  margin-left: auto; /* Pushes the checkbox to the right */
}


.freq-save-btn {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.freq-save-btn:hover {
  background: #0056b3;
}


.laptops-product-coupon {
  display: inline-block; /* Makes the box only as wide as its content */
  padding: 8px 10px; /* Adds padding inside the box */
  background-color: #f8f9fa; /* Light background color */
  border: 1px solid #007bff; /* Blue border color */
  border-radius: 5px; /* Rounded corners */
  color: #007bff; /* Text color to match the border */
  font-weight: bold; /* Make the text bold */
  margin: 10px 0; /* Adds margin above and below the box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 14px;
}




.upload-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for button */
  margin-top: 10px; /* Space between input and button */
  margin-left: 20px; /* Space between input and button */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.upload-button:hover {
  background-color: #45a049; /* Darker green on hover */
}



.slider-container {
  position: relative; /* Make the container relative for absolute positioning of arrows */
}

.image-wrapper {
  position: relative; /* Make the wrapper relative for proper image positioning */
}

.custom-arrow {
  width: 40px; /* Set width for arrows */
  height: 40px; /* Set height for arrows */
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  border: 2px solid rgba(0, 0, 0, 0.4); /* Add border for visibility */
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure arrows are on top */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, border-color 0.3s; /* Add transitions for smooth effects */
}

.custom-arrow:hover {
  background-color: rgb(255, 0, 0); /* Change background on hover */
  border-color: rgba(0, 0, 0, 0.8); /* Darken border on hover */
}


.slick-dots {
  bottom: 10px; /* Position dots */
}

.slick-dots li button:before {
  font-size: 12px; /* Size of the dots */
  color: rgb(255, 255, 255); /* Color of the dots */
}

.slick-dots li.slick-active button:before {
  color: #ff6600; /* Color of the active dot */
}
.image-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
}

.action-icon {
  cursor: pointer;
  font-size: 15px;
  color: #333333;
  transition: color 0.3s;
}

.action-icon:hover {
  color: #007bff; /* Change color on hover */
}



.adminmodal-input1 {
  width: 70%;   
  height: 100px;                    /* Full width */
  padding: 12px 16px;               /* Padding for comfort */
  border: 1px solid #ddd;        /* Border color */
  border-radius: 8px;               /* Rounded corners */
  background-color: #ffffff;        /* Light background */
  color: #333;                      /* Text color */
  font-size: 16px;                  /* Font size */
  font-family: 'Arial', sans-serif; /* Font family */
  resize: none;  
                     /* Disable resizing */
}



/* Style for textarea */
.laptops-card-input1 {
  width: 100%; /* Full width */
  min-height: 19px; /* Minimum height to start with */
  max-height: 300px; /* Optional: Maximum height to prevent overflow */
  padding: 10px; /* Comfortable padding */
  border-radius: 4px; /* Rounded corners */
  background-color: white; /* Light background */
  font-size: 14px; /* Font size */
  box-sizing: border-box;
  border: 1px solid #ddd; /* Border style */
  resize: vertical; /* Allow vertical resizing */
  transition: border-color 0.3s ease; /* Smooth transition */
}

/* Container for icons */
.icons-container {
  display: flex; /* Align icons in a row */
  align-items: center; /* Center icons vertically */
}



.laptops-page {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
  }
  
  .laptops-content {
    margin-left: 250px; /* Adjust based on sidebar width */
    padding: 20px;
  }
  
  .product-label {
    position: absolute; /* Use absolute positioning */
    top: 10px; /* Distance from the top */
    left: 10px; /* Distance from the left */
    background-color: #00A098; /* Background color */
    color: white; /* Text color */
    padding: 5px 10px; /* Padding around the label */
    border-radius: 3px; /* Rounded corners */
    font-size: 12px; /* Font size */
    font-weight: bold; /* Bold text */
    z-index: 1; /* Ensure label is on top of the image */
    box-shadow: 0 4px 15px rgba(0, 160, 152, 0.75); /* Glow effect */
    text-transform: uppercase; /* Make label uppercase */
    letter-spacing: 1px; /* Add letter spacing for style */
    /* transform: rotate(-30deg);  */
    animation: shine 2s infinite linear; /* Add a shine animation */
  }
  
  /* Create a shining effect */
  @keyframes shine {
    0% {
      background-color: #00A098; /* Starting color */
      box-shadow: 0 0 10px rgba(0, 160, 152, 0.5), 0 0 20px rgba(0, 160, 152, 0.3);
    }
    50% {
      background-color: #00D6C1; /* Lighter shining color */
      box-shadow: 0 0 20px rgba(0, 214, 193, 0.8), 0 0 30px rgba(0, 214, 193, 0.6);
    }
    100% {
      background-color: #00A098; /* Back to original color */
      box-shadow: 0 4px 15px rgba(0, 160, 152, 0.75);
    }
  }
  
  .laptops-page-title {
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 24px;
  }
  
  .laptops-card {
    width: 500px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  

  .laptops-card-item {
    font-weight: bold;
    color: #34495e;
  }
  
  .laptops-card-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
/* Group related selectors for consistency */
.laptops-card-input:focus, 
.laptops-card-input1:focus, 
.laptops-card-input2:focus, 
.adminmodal-input:focus, 
.adminmodal-input1:focus, 
.adminmodal-input5:focus, 
.feature-input:focus, 
.feature-textarea:focus {
  border-color: #007bff; /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a glowing effect */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}


/* Optional: Add hover effect for consistency */
.laptops-card-input:hover, 
.laptops-card-input1:hover, 
.laptops-card-input2:hover, 
.adminmodal-input:hover, 
.adminmodal-input1:hover, 
.adminmodal-input5:hover, 
.feature-input:hover, 
.feature-textarea:hover {
  border-color: #0056b3; 
  box-shadow: 0 0 6px rgba(0, 86, 179, 0.6); 
}

  
  .laptops-card-input2 {
    width: 25%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .laptops-card-row3 {
      flex-direction: column; /* Stack items vertically */
    }
  
    .laptops-card-input4{
    
      width: 100%; /* Full width for smaller screens */
    }
  }
  @media (max-width: 480px) {
    .laptops-card-row3 {
      flex-direction: column; /* Stack items vertically */
    }

    .laptops-card-input {
      width: 80%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-sizing: border-box;
  }

    .laptops-card {
      width: 350px;
      margin: 0 auto;
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    

    .freq-modal-content {
      background: white;
      border-radius: 8px;
      width: 325px;
      padding: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      position: relative;
    }
  
    .laptops-card-input4{
    
      width: 100% !important; /* Full width for smaller screens */
    }
  }
  
  .laptops-card-input4 {
    width: 14%; /* Assign 25% width to the Effective Price input */
    padding: 10px; /* Add padding for better UX */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  
  .laptops-card-input5 {
    width: 75%; /* Full width */
    min-height: 19px; /* Minimum height to start with */
    max-height: 300px; /* Optional: Maximum height to prevent overflow */
    padding: 10px; /* Comfortable padding */
    background-color: white; /* Light background */
    font-size: 14px; /* Font size */
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #ddd; /* Border style */
    resize: vertical; /* Allow vertical resizing */
    transition: border-color 0.3s ease; /* Smooth transition */
  }
  
  
  .laptops-card-inputt {
    width: 100%;
    padding-top: 8px;
    padding-left: 2px;
    padding-bottom: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .laptops-add-btn {
    background-color: #1abc9c;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .laptops-add-btn:hover {
    background-color: #16a085;
  }
  
  .laptops-products-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }

  .laptops-product-actual-price {
    color: #e74c3c; /* Red color for actual price */
    font-size: 14px;
  }

  .actual-price{
    text-decoration: line-through; /* Strike through for the actual price */

  }
  
  .laptops-product-pricee {
    font-weight: bold; /* Make selling price bold */
    color: #27ae60; /* Green color for the selling price */
    font-size: 16px;
  }
  
  .laptops-product-deliverycharge {
    font-weight: bold; /* Make selling price bold */
    color: #df8634; /* Green color for the selling price */
    font-size: 14px;
  }
  
  .laptops-product-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px; /* Increase card width */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    background-color: #fff;
  }
  
  .laptops-product-image {
    width: 100%;
    height: 200px; 
    overflow: hidden;
    object-fit: contain;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    margin-bottom: 10px;
  }
  
  .laptops-product-img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 8px; 
  }
  

   /* .laptops-product-image {
    width: 100%;
    height: auto;
  }
  
  .laptops-product-img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
   */

  .laptops-product-details {
    text-align: center;
  }
  
  .laptops-product-features,
  .laptops-product-price {
    margin: 5px 0;

  }
  .laptops-product-features {
    text-align: justify;
  }
  
  .laptops-product-actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
 
  .laptops-product-name {
    font-size: 18px;
    color: #2c3e50;
  }
  .laptops-product-subtitle {
    font-size: 14px;
    color: grey;
  }
  
  .laptops-product-features {
    font-size: 14px;
    color: #4c5455;
  }
  
  .laptops-product-price {
    font-size: 16px;
    color: #e74c3c;
    font-weight: bold;
  }

  .laptops-action-btn {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1rem;
    color: #007bff;
  }
  
  .laptops-action-btn:hover {
    color: #0056b3;
  }
  .laptops-product-actions {
    display: flex;
    gap: 10px;
  }

  .adminmodal5 {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    height: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    zoom: 0.8;
  }

  .editmodal {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    height: 450px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    zoom: 0.9;

  }
  .adminmodal6 {
    position: relative;
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    height: 550px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    zoom: 0.8;

  }
  .adminmodal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%; /* Ensure adminmodal takes 90% of the width on smaller screens */
    margin-left: 10%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    zoom: 0.9;
  }

  .features {
    margin-top: 15px;
  }
  
  .features-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .feature-key {
    min-width: 100px; /* Adjust width as needed */
    font-weight: bold;
    text-align: left;
  }
  
  .feature-value {
    flex: 1;
    text-align: left;
    padding-left: 1px; /* Add some spacing */
  }
  .product-features-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;

    gap: 8px;
  }

  .adminmodal-body {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* Part 1 and Part 2 will be side-by-side by default */
  .part1,
  .part2 {
    flex: 1;
    min-width: 300px; /* Set a minimum width to prevent them from getting too small */
  }
  .custom-arrow:hover {
  background-color: white; /* Prevent background color change */
  color: inherit; /* Ensure the color remains the same */
  box-shadow: inherit; /* Keep box shadow unaffected on hover */
  border: none;
}

.custom-arrow{
  border: none;
}


  /* For smaller screens, stack Part 1 and Part 2 vertically */
  @media (max-width: 768px) {
    .adminmodal-body {
      flex-direction: column;
      gap: 10px; /* Reduce the gap when stacked */
    }
  
    .part2 {
      margin-top: 20px; /* Space between part 1 and part 2 */
    }
  }
  
  
 
  
  .feature-item {
    display: flex;
    justify-content: space-between; /* Ensures space between label and input */
    align-items: center; /* Vertically aligns label and input */
    gap: 10px;
  }
  
  .feature-label {
    min-width: 100px; /* Optional: Ensures consistent label width */
    font-weight: bold;
  }
  
  .feature-input {
    width: 100%; /* Adjust width as needed */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    text-align: left; /* Optional: Align text inside the input to the right */
  }

  
  .feature-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .product-features-container {
      width: 100%;
    }
  
    .feature-label {
      font-size: 12px;
    }
  
    .feature-input, .feature-textarea {
      font-size: 14px;
    }
  }
  

  
  .adminmodal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .adminmodal-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .adminmodal-input5 {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .adminmodal-update-btn, .adminmodal-cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    float: end;
  }
  
  .adminmodal-update-btn {
    background-color: #4CAF50;
    color: #fff;
  }
  
  .adminmodal-cancel-btn {
    background-color: #f44336;
    color: #fff;
  }
  
  .adminmodal-update-btn:hover {
    background-color: #45a049;
  }
  
  .adminmodal-cancel-btn:hover {
    background-color: #e41f1f;
  }
  
  .adminmodal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .adminmodal h2 {
    margin: 0; /* Remove default margin */
    text-align: center; /* Center-align the title */
    flex: 1; /* Allow the title to take up available space */
  }
  
  .adminmodal-close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .adminmodal-close-btn:hover {
    color: #f44336; /* Change color on hover */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .adminmodal {
      width: 80%;
    }
  
    .adminmodal-input {
      font-size: 14px;
    }
  
    .adminmodal-update-btn, .adminmodal-cancel-btn {
      font-size: 14px;
      padding: 8px 16px;
      margin-right: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .laptops-product-image {
      width: 100%;
      height: 200px; 
      overflow: hidden;
      /* display: flex; */
      /* justify-content: center; */
      /* align-items: center; */
      margin-bottom: 5px;
    }
    .slick-dots{
      display: none;
    }
    
    .adminmodal {
      width: 70%;
      /* margin-left: 80px; */
    }
  
    .adminmodal-input {
      font-size: 14px;
    }
  
    .adminmodal-update-btn, .adminmodal-cancel-btn {
      font-size: 14px;
      padding: 8px 12px;
      margin-right: 5px;
    }
  }

  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .laptops-content {
      margin-left: 200px; /* Adjust based on sidebar width */
    }
  }
  
  @media (max-width: 992px) {
    .laptops-content {
      margin-left: 150px; /* Adjust based on sidebar width */
    }
  
    .laptops-card-header {
      display: none; /* Hide header on smaller screens */
    }
  
    .feature-1 {
      display: none; /* Hide header on smaller screens */
    }
  
    .laptops-card-row,
    .laptops-product-card {
      grid-template-columns: 1fr;
    }
  }

  
  .ad-product-form {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    border-radius: 8px;
    width: auto; /* Expands based on content */
    max-width: 100%;
    min-width: 350px; /* Prevents it from being too small */
    flex-wrap: wrap; /* Allows wrapping in smaller screens */
    /* background: #f9f9f9; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid #ddd; */
  }
  
  /* Ensure inputs expand properly */
  .ad-form-input {
    flex: 1; /* Allows elements to take equal space */
    min-width: 180px; /* Prevents elements from shrinking too much */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    background: #fff;
  }
  
  .ad-form-btn {
    background: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    transition: background 0.3s, transform 0.2s;
  }
  
  .ad-form-btn:hover {
    background: #218838;
    transform: scale(1.05);
  }
  
  /* Responsive Layout */
  @media (max-width: 600px) {
    .ad-product-form {
      flex-direction: column; /* Stack elements vertically */
      width: 100%;
    }
    
    .ad-form-input,
    .ad-form-btn {
      width: 100%;
    }
  }
  
  
  
  @media (max-width: 768px) {
    .laptops-content {
      margin-left:0px; /* Start form 100px from the left on smaller screens */
      padding: 10px;
    }
  }


  /* Adjust the flex direction for responsive view */
@media (max-width: 768px) { /* You can adjust the width as needed */
  .features-input-container {
      flex-direction: column; /* Stack items vertically */
  }
  
  .laptops-card-input2 {
      width: 100%; /* Set input width to full */
  }

  .icons-container {
display: flex;    margin-top: 10px; /* Space above icons */
}
  
 
}
    .features-input-container {
      display: flex; /* Use flexbox for alignment */
      align-items: center; /* Center items vertically */
      position: relative; /* Allow positioning of child elements */
      width: 100%; /* Ensure full width */
      margin-top: 15px; /* Adjust margin if needed */
      margin-bottom: 10px;
      gap: 37px;
    }
    .features-input-container5 {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }
    
    .feature-grid {
      display: grid;
      grid-template-columns: 1fr 2fr; /* Two columns: labels and inputs */
      align-items: center;
      gap: 10px; /* Spacing between items */
      width: 100%;
    }
    
    .feature-label {
      font-weight: bold;
      text-align: left;
    }
  .pipe-icon {
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed */
    color: #000000; /* Change color to match your theme */
    margin-left: 10px; /* Space between textarea and pipe icon */
  }
  
  .info-icon {
    cursor: pointer;
    padding: 5px;
    background: #007bff;
    color: white;
    border-radius: 50%;
    text-align: center;
    width: 24px; /* Adjust size */
    height: 24px; /* Adjust size */
    font-size: 14px; /* Font size for the 'i' */
    margin-left: 10px; /* Space between pipe icon and info icon */
    display: flex; /* Use flex to center content */
    align-items: center; /* Center the 'i' vertically */
    justify-content: center; /* Center the 'i' horizontally */
  }
  
 
  
  .info-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1008; /* Ensure it's on top */
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 300px; /* Adjust width as needed */
    position: relative; /* For close button positioning */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  @media (max-width: 600px) {
    .popup-content {
      width: 90%; /* Responsive width for small screens */
    }
  }
  .product-feature-list {
    list-style-type: none; /* Remove bullets */
    padding: 0;
    margin: 0;
  }
  
  .product-feature-list li {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal-width columns */
    gap: 10px; /* Space between the key and value */
    padding: 5px 0;
    font-size: 16px;
    color: #333;
    align-items: center; /* Vertically align key and value */
  }
  
  