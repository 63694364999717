.slider-prev-arrow,
.slider-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
}

.slider-prev-arrow {
  left: 0px;
}

.slider-next-arrow {
  right: 0px;
}

.slider-prev-arrow:hover,
.slider-next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}


.spinner-container {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100vh; /* Full height of the viewport */
  width: 100%; /* Full width of the viewport */
  top: 0; /* Aligns to the top */
  left: 0; /* Aligns to the left */
  z-index: 1000; /* Ensures it is above other content */
}

.spinner {
  --size: 30px;
  --first: #005bba;
  --second: #fed500;
  width: 100px;
  height: 100px;
  position: relative;
  animation: spin 3s linear infinite;
}

.spinner::before,
.spinner::after {
  content: "";
  width: var(--size);
  height: var(--size);
  border: 4px solid var(--first);
  border-top: 4px solid var(--second);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spinRing 1.5s ease-out infinite;
  box-shadow: 0 0 10px var(--first);
}

.spinner::before {
  filter: blur(10px);
}

@keyframes spinRing {
  0% {
      transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
      transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.homepage-container {
  width: 100%;
  max-height: 65vh; 
  height: auto; /* Let the height be determined by the content/image size */
  overflow: hidden;
  position: relative;
}

.slider-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure positioning context for the overlay */

}

.slider-image {
  width: 100%;
  height: 370px; /* Keep the height auto to maintain aspect ratio */
  background-color: black;
  background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.text-overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the overlay */
  padding: 2vh 2vw; /* Reduce padding for smaller screens */
  border-radius: 15px;
  max-width: 90%; /* Adjust max width for smaller screens */
  color: #fff;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
  animation: fadeIn 1.5s ease-in-out;
  pointer-events: auto;
}

.overlay-title {
  font-size: 3vw;
  color: white;
  font-weight: 900;
  margin-bottom: 1vh; /* Reduced margin for better fitting */
  text-transform: uppercase;
  letter-spacing: 0.2vw;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
  animation: slideInFromLeft 1.2s ease-out;
}

.overlay-description {
  font-size: 1.5vw;
  font-weight: 300;
  margin-bottom: 1vh; /* Reduced margin for better fitting */
  line-height: 1.4;
  color: #f1f1f1;
  animation: slideInFromRight 1.2s ease-out;
}

.shop-button-link {
  text-decoration: none;
  color: white;
}

.shop-button {
  padding: 1.5vh 3vw;
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 1.1vw;
  font-weight: 200;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.shop-button.hovered {
  background-color: transparent;
  border: 2px solid white;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .overlay-title {
    font-size: 2vw; /* Reduce title size */
  }
  .homepage-container {
    width: 100%;
    max-height: 70vh; 
    height: auto; /* Let the height be determined by the content/image size */
    overflow: hidden;
    position: relative;
  }
  .slider-image-container {
    width: 100%; /* Full width for the image container */
    display: flex; /* Use flex to center image */
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
    position: relative; /* Position context for the overlay */
    height: 145px; /* Set a fixed height for the image container */
  }

  .overlay-description {
    font-size: 2vw; /* Reduce description size */
  }

  .shop-button {
    font-size: 2vw; /* Reduce button font size */
    padding: 1.2vh 3vw; /* Adjust button padding */
    margin-bottom: 1vh
  }

  .text-overlay {
    padding: 1.5vh 2vw; /* Reduce padding */
    max-width: 80%; /* Ensure overlay fits within the image container */
  }

  
  .slider-image {
    width: 100%;
    height: auto;
    max-height: 400px; /* Set a max height to maintain size on mobile */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .overlay-title {
    font-size: 3vw; /* Further reduce title size */
  }
  .custom-dotss{
    display: none;
  }

  .homepage-container {
    width: 100%;
    max-height: 70vh; 
    height: auto; /* Let the height be determined by the content/image size */
    overflow: hidden;
    position: relative;
  }
  

  .overlay-description {
    font-size: 2.5vw; /* Further reduce description size */
  }

  .shop-button {
    font-size: 2.8vw; /* Adjust button size */
    padding: 0.9vh 6vw; /* Reduce padding */
  }

  .text-overlay {
    padding: 0vh 1.5vw; /* Reduce padding for small screens */
    max-width: 80%; /* Keep overlay within image bounds */
  }

  .slider-image {
    width: 100%;
    height: 100%; /* Ensure image keeps aspect ratio */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* margin-top: 10px; */

  }
}

.box2{
   background-color: white;
  padding: 17px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}


