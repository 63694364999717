/* Container for centering the form and setting background color */
.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    padding: 0 20px;
  }
  
  /* Form styling with background, border-radius, and shadow */
  .change-password-form {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 400px;
    width: 100%;
    /* margin-left: 90px; */

  }
  
  /* Heading styling */
  .change-password-form h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  /* Group styling for spacing */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    /* color: #666; */
  }
  
  /* Container for input field with relative positioning */
  .input-container {
    position: relative;
  }
  
  /* Input field styling */
  .input-container input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Styling for the password toggle button */
  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #666;
  }
  
  /* Submit button styling */
  .submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Hover state for the submit button */
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styling for smaller screens */
  @media (max-width: 768px) {
    .change-password-form {
      padding: 15px;
      max-width: 100%;
    }
    
    .change-password-form h1 {
      font-size: 20px;
    }
    
    .input-container input {
      font-size: 14px;
    }
    
    .submit-button {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .change-password-form {
      padding: 10px;
    }
    
    .change-password-form h1 {
      font-size: 18px;
    }
    
    .input-container input {
      font-size: 12px;
    }
    
    .submit-button {
      font-size: 12px;
    }
  }