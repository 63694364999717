/* Default Header3 Styles */
.header3 {
    /* background-color: black; */
    background-color: #001a35;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #818181;
    position: relative;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

.hamburger {
    display: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: 15px;
    color: white;
}

.header3 nav {
    display: flex;
    gap: 20px;
    align-items: center;

}


.header3 nav a {
    color: white;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    /* background-color: black; */
    /* border: 1px solid #ddd; */
}


.nav-item{
    color: white;


}
.header3 nav a:hover {
    color: #ff6600;
}
.activelink:hover {
    color: #ff6600;
}


.fa-icons {
    font-size: 19px; /* Adjust icon size */
    margin-right: 5px; /* Space between icon and text */
    color: white; /* Adjust icon color */
}
.header3 nav .fa-icons:hover {
    color: #ff6600;
}


/* Dropdown Styles */
.nav-item {
    position: relative;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #000000;
    border: 1px solid #ccc;
    border-radius: 1px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 160px;
    margin-top: 10px;
}

.nav-item:hover .dropdown,
.nav-item .dropdown {
    display: block;
}
/* Basic style for dropdown links */
.dropdown a {
    display: block;
    padding: 10px 15px; /* Add more horizontal padding */
    color: #fff; /* Change text color to white for better contrast */
    text-decoration: none;
    background-color: #333; /* Darker background for dropdown items */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Add a subtle border between items */
    font-family: 'calibri', sans-serif; /* Ensure clean, legible font */
    font-size: 14px; /* Make the font size larger for better readability */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    /* z-index: 999; */
}

/* Hover effect to highlight dropdown items */

/* Add a slight shadow to dropdown items */
.dropdown a {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Add subtle shadow for a 3D effect */
}

/* For mobile-friendly dropdown items */
@media (max-width: 768px) {
    .dropdown a {
        padding: 15px 20px; /* Increase padding for mobile view */
        font-size: 18px; /* Increase font size for easier reading on small screens */
    }
    .fa-icons.active {
        color: #ff6600 !important; /* Change icon color when active */
    }
}

/* Active state for the dropdown item */
.dropdown a.active {
    color: #fff;
}

/* .dropdown a:hover {
    background-color: #f5f5f5;
} */

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    /* Reset Header3 styles for small screens */
    .header3 {
        background-color: transparent;
        border-top: none;
        padding: 0;
    }

    .fa-icons.active {
        color: #ff6600; /* Change icon color when active */
    }
    .hamburger {
        display: block;
    }

    .header3 nav {
        position: fixed; /* Fixed positioning to cover the entire page */
        top: 0;
        right: 0; /* Set left to 0 to align with the screen edge */
        right: auto; /* Remove auto width control */
        bottom: 0;
        background-color: #ffffff;
        width: 380px; /* Define the width of the navigation menu */
        flex-direction: column;
        padding: 50px 20px; /* Add some padding for space */
        display: flex;
        justify-content: flex-start; /* Align items to the left */
        align-items: flex-start; /* Align links to the left */
        text-align: left;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, width 0.3s ease; /* Smooth transitions for width */
        opacity: 0; /* Hidden by default */
        visibility: hidden; /* Hidden by default */
        z-index: 9000; /* Ensure nav is above other content */
        overflow: auto;
        box-shadow: 1px 2px 5px 5px rgba(0, 0, 0, 0.3); /* Add box shadow */
    }
    
    .header3 nav.open {
        opacity: 1; /* Show when open */
        visibility: visible; /* Ensure visibility when open */
        background-color: #001a35;
    }
    .header3 nav a {
        font-size: 16px;
        padding: 10px;
    }

    .dropdown {
        position: static;
        box-shadow: none;
        border: none;
        border-radius: 0;
        background-color: #f5f5f5;
    }

    .dropdown a {
        padding: 10px;
    }
}
.nav-link.active {
    color: #ff6600; /* Change text color for active links */
}

.nav-item.active > span {
    color: #ff6600; /* Change text color for active parent item */
}

.fa-icons.active {
    color: #ff6600; /* Change icon color when active */
}

.dropdown .nav-link.active {
    color: #ff6600; /* Active state for dropdown items */
}


.activelink {
    color: white;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}