.my-orders {
    padding: 20px;
    background-color: #f0f2f5;
  }
  
 
  .my-orders h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
    text-align: center;
  }
 
  
  
  .order-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .order-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s, transform 0.3s;
    width: 100%;
    max-width: 350px;
  }
  
  .order-card:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .order-status {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    text-transform: capitalize;
  }
  
  .order-status.completed {
    background-color: #d4edda;
    color: #155724;
  }
  
  .order-status.pending {
    background-color: #fff3cd;
    color: #856404;
  }
  
  .order-status.cancelled {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .view-details-button {
    background-color: #287bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;

  }
  
  .view-details-button:hover {
    background-color: #0056b3;
  }
  
  .no-orders {
    text-align: center;
    font-size: 18px;
    color: #555;
  }

  .order-status {
    padding: 6px 15px; /* Increased padding for a more prominent look */
    border-radius: 8px; /* More rounded corners for a modern feel */
    font-weight: bold; /* Keep bold text */
    color: white; /* White text for better contrast */
    background-color: #28a745; /* Green background for successful status */
    display: inline-block; /* Make the status label inline-block */
    text-align: center; /* Center the text */
    font-size: 12px; /* Slightly increased font size */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  }
  
  .order-status:hover {
    background-color: #218838; /* Darker green on hover */
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  .order-status.shipped {
    background-color: #ffc107; /* Yellow background for 'Shipped' status */
    color: #212529; /* Dark text for better contrast on yellow */
  }
  
  .order-status.out-for-delivery {
    background-color: #17a2b8; /* Blue background for 'Out for Delivery' status */
  }
  
  .order-status.delivered {
    background-color: #007bff; /* Blue background for 'Delivered' status */
  }
  
  .order-status.pending {
    background-color: #dc3545; /* Red background for 'Pending' or error status */
    color: white;
  }
  
  /* Additional styles for no orders message */
  .no-orders {
    text-align: center;
    font-size: 1.2em;
    color: #555; /* Slightly darker text for contrast */
  }
  