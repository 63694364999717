.wishlist-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 330px;
  height: 100vh;
  background-color: #ffffff;
  color: #333;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 0.6s ease;
  padding-top: 10px;
  z-index: 1004;
  overflow: auto;
}

.wishlist-sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #333 !important;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.wishlist-sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
}

.wishlist-sidebar-body {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;

}

.wishlist-sidebar-body p {
  /* margin-top: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;

  
}
.empty-wishlist{

  margin-top: 50%; /* Remove default margin */
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically if needed */
}
.wishlist-sidebar-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wishlist-sidebar-body li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.wishlist-sidebar-body img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item-details h3 {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.item-details p {
  margin: 0;
  color: #555;
  font-size: 12px;
}




.add-to-cart-btn, .remove-btn {
  padding: 5px 10px;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1;
  margin-right: 0px;
}

.add-to-cart-btn {
  background-color: #28a745; /* Green */
}

.remove-btn {
  background-color: #ff4d4f; /* Red */
}

.add-to-cart-btn:hover {
  background-color: #218838; /* Darker green on hover */
}

.remove-btn:hover {
  background-color: #dc3545; /* Darker red on hover */
}

@media screen and (max-width: 768px) {
  .wishlist-sidebar-body li {
    flex-direction: row;
  }

  
  .item-price {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .item-details h3, .item-details p {
    margin: 0;
  }

  
}

@media screen and (max-width: 480px) {
  .wishlist-sidebar {
    width: 88%;
    padding: 0;
  }

  .wishlist-sidebar-body img {
    width: 50px;
    height: 50px;
  }

  .add-to-cart-btn, .remove-btn {
    font-size: 10px;
    padding: 4px 8px;
  }

  .item-actions {
    flex-direction: row;
    /* gap: 10px; */
  }
}

/* /////////// */


.wishlist-sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}


.item-image {
  width: 80px;
  height: auto;
  margin-right: 15px;
}

.item-name {
  font-size: 1rem;
  margin: 0;
}



.item-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.item-price {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.add-to-cart-btn,
.remove-btn {
  /* margin-bottom: 10px; */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 3px;
  width: 100%;
  text-align: center;
}

.remove-btn {
  background-color: #dc3545;
}

.add-to-cart-btn:hover,
.remove-btn:hover {
  opacity: 0.9;
}

.item-features {
  display: -webkit-box; /* Use a box layout for controlling text lines */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Add ellipsis at the end of the text */
  white-space: normal; /* Allow text to wrap */

  font-size: 16px; /* Adjust as per your design */
  line-height: 2; /* Set line height */
}
