.address-page-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px;
  }
  
  .address-form-container {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .submitted-addresses-container {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    text-align: center;
  }
  
  .address-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group2-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group2 {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .form-group2 label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .form-group2 input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  

  .address-card {
    position: relative;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    overflow: hidden; /* Ensure content does not overflow */
    max-width: 100%; /* Ensure the card does not exceed the container width */
  }
  
  .address-card p {
    margin: 5px 0;
    display: flex; /* Use flexbox for proper label-value alignment */
    white-space: normal; /* Allows text to wrap */
    overflow-wrap: break-word; /* Breaks long words to prevent overflow */
    word-wrap: break-word; /* For compatibility with older browsers */
    hyphens: auto; /* Allows hyphenation for better wrapping */
  }
  
  .address-card strong {
    flex: 0 0 150px; /* Set fixed width for the label part (e.g., "Name:", "Street Address:") */
    font-weight: bold;
  }
  
  .address-card span {
    flex: 1; /* Allow the values to take up the remaining space */
    word-wrap: break-word; /* Wrap long text */
    overflow-wrap: break-word; /* Ensure long words break appropriately */
    white-space: normal; /* Make sure text wraps within the container */
    min-width: 0; /* Important: allows the flex item to shrink properly */
  }
  
  .button-container {
    position: relative;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
  }
  
  .adr-btn {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .adr-btn:hover {
    background-color: #0056b3;
  }


  .back-to-cart-button {
    position: absolute;  /* Position it absolutely */
    top: 150px;          /* Distance from the top of the container */
    right: 10px;        /* Distance from the right of the container */
    background-color: #007BFF; /* Button color */
    color: white;       /* Text color */
    border: none;       /* Remove border */
    padding: 8px 10px; /* Button padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;    /* Pointer on hover */
    font-size: 13px;    /* Font size */
    transition: background-color 0.3s; /* Smooth background color change */
    z-index: 10;        /* Ensure button appears above other content */
  }
  
  .back-to-cart-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  /* Responsive Styles */
@media (max-width: 768px) {
  .back-to-cart-button {
    top: 160px;          /* Adjust top distance for smaller screens */
    right: 5px;          /* Reduce right distance for smaller screens */
    padding: 8px 12px;   /* Adjust padding for smaller screens */
    font-size: 14px;     /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .back-to-cart-button {
    top: 200px;          /* Further adjust top distance for very small screens */
    right: 5px;          /* Keep right distance */
    padding: 6px 10px;   /* Further adjust padding for very small screens */
    font-size: 12px;     /* Further adjust font size for very small screens */
  }
}
  
  
  @media (max-width: 768px) {
    .address-page-container {
      flex-direction: column;
    }

    .address-form-container {
      flex: 1;
      width: 100%;
      background-color: #f9f9f9;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .form-group2-row {
      display: inline;
    }

    
  }
  .model {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* margin-top: 10px; */
    z-index: 1000;
  }
  
  .model-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    max-width: 90%;
    position: relative;
  }
  
  .closee {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  

  
  .model form {
    display: flex;
    flex-direction: column;
  }
  
 
  
  .model button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .model button:hover {
    background-color: #0056b3;
  }
  


  .select-toggle-btn, .delete-selected-btn {
    margin-left: 20px;
    padding: 5px 10px;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-toggle-btn:hover, .delete-selected-btn:hover {
    background-color: #e65c00;
  }
  
  .address-card input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .address-card {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .address-card .button-container {
    margin-top: 10px;
  }
  
  .adr-btn {
    margin-right: 5px;
  }

  .headerrr-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  
  .headerrr-controls {
    display: flex;
    align-items: center;
  }
  
  .select-toggle-btn {
    margin-right: 10px; /* Space between the button and checkbox */
    padding: 5px 10px;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-toggle-btn:hover {
    background-color: #e65c00;
  }
  
  .select-all-checkbox {
    margin-left: 10px;
  }