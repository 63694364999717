.ad-page {
    padding: 30px;
    background-color: #f4f4f4;
    font-family: 'calibri', sans-serif;
}

/* .ad-second-page{
    padding: 10px;
    padding-bottom: 30px;   
    padding-right: 30px;   
    padding-left: 30px;   
    background-color: #2D6978;
    border-radius: 10px;
} */

.ad-second-page {
    padding: 10px;
    padding-bottom: 30px;   
    padding-right: 30px;   
    padding-left: 30px;   
    background: linear-gradient(135deg, rgba(255, 223, 0, 0.8), rgba(210, 180, 140, 0.8));
    border-radius: 10px;
}

.ad-first-page{
    padding-top: 30px;
    padding-bottom: 30px;   
    padding-right: 30px;   
    padding-left: 30px;   
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.ads-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px; /* Reduced gap for tighter spacing */
    position: relative;
}

.ad {
    position: relative;
    flex: 1 1 calc(24% - 10px); /* Adjusted to slightly less than 25% for margin */
    max-width: calc(24% - 10px); /* Adjust max width to fit 4 per row with margin */
    /* background-color: #2D6978; */
    /* border-radius: 10px; */
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 1px;
    height: 400px; /* Set a fixed height for the ad */
}

.ad:hover {
    transform: scale(1.02);
}

.ad-image-card {
    width: 100%;
    padding-top: 100%; /* Adjusted height for portrait ratio */
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

}

.add-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fits within portrait dimensions */
    object-position: center;
    transition: transform 0.3s ease-in-out;
}

.add-image:hover {
    transform: scale(1.05);
}

/* Responsive Styles */


@media (max-width: 480px) {
   
    .ad-image-card {
        width: 100%;
        padding-top: 100%; /* Adjusted height for portrait ratio */
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
}



.ad-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5); /* Optional: Background for text */
}

.ad-text h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.ad-text p {
    font-size: 16px;
    margin-bottom: 15px;
}

.shop-now-button2 {
    font-family: 'calibri', sans-serif;
    color: #fff; /* Text color */
    border: 2px solid #fff; /* White border */
    border-radius: 50px;
    padding: 6px 50px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.shop-now-button2:hover {
   
        background-color: transparent;
        border: 2px solid white;
    color: white;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .ad {
        flex: 1 1 calc(50% - 20px); /* 2 items per row */
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .ad {
        flex: 1 1 100%; /* 1 item per row */
        max-width: 100%;
        height: 300px; /* Allow height to adjust automatically */
        object-fit: contain;
    }

    .ad-text h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    
    .ad-text p {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Full height of the container */
}
.swiper-arrows {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 10; /* Ensure the arrows are above the images */
  }
  
  .swiper-arrow {
    background-color: rgba(0, 0, 0, 0.185);
    color: white;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    /* border-radius: 50%; */
    z-index: 10; /* Ensure the arrows are above the images */
  }
  
  .swiper-arrow:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .swiper-arrow.prev {
    left: 10px;
  }
  
  .swiper-arrow.next {
    right: 10px;
  }
  
  @media (min-width: 769px) {
    /* Hide arrows on larger screens */
    .swiper-arrows {
      display: none;
    }
  }